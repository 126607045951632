import {useIntegrator} from "../../integrator.context";
import AssetImage from "../../elements/assetImage.element";
import LocalizationInput from "../../elements/localizationInput.element";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {UpdateResourceCommand} from "../../commands/updateResource.command";
import {AddResourceCommand} from "../../commands/addResource.command";
import {RemoveResourceCommand} from "../../commands/removeResource.command";
import BooleanInput from "../../elements/booleanInput.element";
import MetadataDragLabel from "../../elements/metadataDragLabel";
import {ResourceData} from "../../models/remoteConstants.model";
import {DropTargetMonitor, useDrop} from "react-dnd";
import MetadataLabel from "../../elements/metadataLabel.element";
import IconButton from "../../elements/IconButton";
import {ChangeConstantsCommand} from "../../commands/changeConstants.command";
import {moveObjectDown, moveObjectUp} from "../../utils/array.utils";
import ImagePackElement from "../../elements/imagePack.element";

function ResourcesEditor() {
    const {state, executeCommand} = useIntegrator();

    return (
        <div className={'layout horizontal'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout vertical gap'} style={{gap: 10, flexGrow: 1}}>
                <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', gap: 10, height: 0}}>
                    {state.resources !== undefined && state.resources.map((resource, index) => {
                        return <ResourceItem key={index} resource={resource}/>
                    })}
                </div>
                <button onClick={() => executeCommand(new AddResourceCommand())}>Add New Resource</button>
            </div>
            <div className={'layout vertical gap'} style={{gap: 10}}>
                <div className={'layout vertical gap box flex-grow'}>
                    <p className={'text header'}>{`Tech Actions`}</p>
                    <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', width: '25vw'}}>
                        {state.techActionAttributes !== undefined && state.techActionAttributes !== null && state.techActionAttributes.map((metadata, index) => {
                            return <MetadataDragLabel key={index} uid={metadata.uid} type={'attribute'} draggable={() => true}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

function ResourceItem(props: {resource: ResourceData}) {
    const {executeCommand} = useIntegrator();

    function checkCanDrop(monitor: DropTargetMonitor<any, void>): boolean {
        if (!monitor.isOver({shallow: true})) {
            return false;
        }

        return !props.resource.attributes?.includes(monitor.getItem().id);
    }

    const [{canDrop}, drop] = useDrop({
        accept: 'attribute',
        drop: (item: any, monitor) => {
            if (!checkCanDrop(monitor)) {
                return;
            }

            props.resource.attributes ??= [];
            props.resource.attributes.push(item.id)
            executeCommand(new UpdateResourceCommand(props.resource))
        },
        collect: (monitor) => {
            return {
                canDrop: checkCanDrop(monitor)
            };
        },
    });

    return (
        <div ref={drop} className={'box'} style={{
            opacity: props.resource.active ? 1 : 0.5,
            border: `solid 1px ${canDrop ? 'var(--tint-color)' : 'var(--overlay-color)'}`
        }}>
            <div className={'layout horizontal gap'} style={{gap: 20}}>
                <ImagePackElement id={props.resource.id} size={80}/>
                <div className={'layout vertical gap'}>
                    <div className={'layout horizontal gap'}>
                        <LocalizationInput id={props.resource.id} style={{width: 100}}/>
                        <BooleanInput value={props.resource.active} onChange={(value) => {
                            props.resource.active = value;
                            executeCommand(new UpdateResourceCommand(props.resource));
                        }}/>
                        <IconButton icon={'trash'} onClick={() => {
                            executeCommand(new RemoveResourceCommand(props.resource.id));
                        }}/>
                        <IconButton icon={'arrow-down'} onClick={() => {
                            executeCommand(new ChangeConstantsCommand(data => {
                                data.resources = moveObjectDown(data.resources, props.resource);
                            }));
                        }}/>
                        <IconButton icon={'arrow-up'} onClick={() => {
                            executeCommand(new ChangeConstantsCommand(data => {
                                data.resources = moveObjectUp(data.resources, props.resource);
                            }));
                        }}/>
                    </div>
                </div>
                <div className={'layout vertical gap'} style={{gap: 20}}>
                    <p className={'text header'}>World Generation</p>
                    <div className={'layout vertical gap'}>
                        <NumberInputLabel title={'Rarity'} value={props.resource.rarity}
                                          onChange={(value) => {
                                              props.resource.rarity = value;
                                              executeCommand(new UpdateResourceCommand(props.resource));
                                          }}/>
                    </div>
                    <p className={'text header'}>Statistics</p>
                    <div className={'layout vertical gap'}>
                        <NumberInputLabel title={'Start Count'} value={props.resource.startCount}
                                          onChange={(value) => {
                                              props.resource.startCount = value;
                                              executeCommand(new UpdateResourceCommand(props.resource));
                                          }}/>
                        <NumberInputLabel title={'Vision Modifier'} value={props.resource.visionModifier}
                                          onChange={(value) => {
                                              props.resource.visionModifier = value;
                                              executeCommand(new UpdateResourceCommand(props.resource));
                                          }}/>
                    </div>
                </div>
                <div className={'layout vertical gap flex-grow'}>
                    <div className={'layout vertical gap'}
                         style={{
                             borderRadius: '5px',
                             flexGrow: 1
                         }}>
                        <p className={'text header'}>Tech Actions</p>
                        {props.resource.attributes && props.resource.attributes.map((uid, i) => {
                            return <MetadataLabel key={i} uid={uid} onDelete={() => {
                                props.resource.attributes = props.resource.attributes.filter(r => r !== uid);
                                executeCommand(new UpdateResourceCommand(props.resource));
                            }}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourcesEditor;