import NumberInput from "./numberInput.element";
import {CSSProperties} from "react";
import {useApp} from "../app.context";

interface NumberInputLabelProps {
    title: string;
    value: number;
    step?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    onChange(value: number): void;
}

function NumberInputLabel(props: NumberInputLabelProps) {
    const {getLocalizationValue} = useApp();

    return (
        <div className={'layout horizontal stretch gap flex-grow'} style={{...{alignItems: 'center', gap: 10}, ...props.style}}>
            <p className={'flex-grow'}>{getLocalizationValue(props.title)}</p>
            <NumberInput value={props.value} step={props.step} style={props.inputStyle} onChange={props.onChange}/>
        </div>
    )
}

export default NumberInputLabel;
