import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class ChangeLocalizationValueCommand extends CommandBase {
    key: string;
    value: string;
    language: string;

    constructor(key: string, value: string, language: string) {
        super();
        this.key = key;
        this.value = value;
        this.language = language;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.localization[this.language][this.key] = this.value;
        return true;
    }
}