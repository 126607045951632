import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveTechUnlockCommand extends CommandBase {
    id: string;
    unlockId: string;

    constructor(id: string, unlockId: string) {
        super();
        this.id = id;
        this.unlockId = unlockId;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.techTree.findIndex(u => u.id === this.id);
        if (index < 0) {
            return false;
        }

        const unlockIndex = data.techTree[index].unlocks.indexOf(this.unlockId);
        if (unlockIndex < 0) {
            return false;
        }

        data.techTree[index].unlocks.splice(unlockIndex, 1);
        return true;
    }
}