import {CSSProperties} from "react";

interface NumberInputProps {
    value: number;
    step?: string;
    style?: CSSProperties;
    onChange(value: number): void;
}

function NumberInput(props: NumberInputProps) {
    return (
        <input value={props.value} type={'number'} step={props.step ?? "0.01"} style={props.style} onChange={(v) => props.onChange(v.target.valueAsNumber)}/>
    )
}

export default NumberInput;