import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddImageToPackCommand extends CommandBase {
    id: string;
    level: number;
    variation: number;

    constructor(id: string, level: number, variation: number) {
        super();
        this.id = id;
        this.level = level;
        this.variation = variation;
    }

    execute(data: RemoteConstantsModel): boolean {
        const levelCount = data.imagePacks[this.id].length;
        if (this.level > levelCount - 1) {
            data.imagePacks[this.id].push([]);
        }

        const key = `${this.id}_${this.level}_${this.variation}`;
        const exists = data.imagePacks[this.id][this.level].length > this.variation;
        if (exists) {
            return false;
        }

        data.imagePacks[this.id][this.level].push(key);
        return true;
    }
}