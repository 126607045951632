import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddImageCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.images[this.id] = {
            fileName: '',
            pixelMultiplier: 800,
            offsetX: 0.5,
            offsetY: 0.5
        }

        return true;
    }
}