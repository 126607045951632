import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {ChangeLocalizationValuesCommand} from "./changeLocalizationValues.command";

export class SetAttributeLocalizationCommand extends CommandBase {
    uid: string;
    id: string;

    constructor(uid: string, id: string) {
        super();
        this.uid = uid;
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        const localeKey = `${this.uid}`;
        new ChangeLocalizationValuesCommand(localeKey, this.id).execute(data);
        new ChangeLocalizationValuesCommand(`${localeKey}.description`, `${this.id} description`).execute(data);
        return true;
    }
}