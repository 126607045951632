import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveTileCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.tiles.findIndex(u => u.id === this.id);
        if (index < 0) {
            return false;
        }

        data.tiles.splice(index, 1);
        for (const key in data.localization) {
            delete data.localization[key][this.id];
        }

        return true;
    }
}