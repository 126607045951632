import {useApp} from "../../app.context";
import {useIntegrator} from "../../integrator.context";
import AssetImage from "../../elements/assetImage.element";
import {AddImageToPackCommand} from "../../commands/addImageToPack.command";

function ImagePackView() {
    const {state, executeCommand} = useIntegrator();
    const {imagePackId, setImagePackId, getLocalizationValue} = useApp();

    const id = imagePackId ?? '';
    const images = state.imagePacks[id];

    function constructPack(): string[][] {
        const pack: string[][] = [];

        for (let i = 0; i < images.length; i++) {
            pack.push([]);

            const variations = images[i];
            for (let j = 0; j < variations.length; j++) {
                pack[i].push(variations[j]);
            }

            pack[i].push(constructNextKey(id, i, variations.length))
        }

        pack.push([constructNextKey(id, images.length, 0)]);
        return pack;
    }

    function constructNextKey(key: string, level: number, variation: number): string {
        return `${key}_${level}_${variation}`;
    }

    return (
        <div className={'layout vertical overlay'} onClick={() => {
            setImagePackId(undefined);
        }}>
            <div className={'layout vertical gap box'} style={{padding: 10, gap: 20, width: 400, maxHeight: 400, overflow: 'auto'}}
                 onClick={e => e.stopPropagation()}
            >
                <p className={'text header'}>{getLocalizationValue(id)}</p>
                <div>
                    <p>Horizontal: Progression</p>
                    <p>Vertical: Variations</p>
                </div>
                <div className={'layout horizontal gap'}>
                    {constructPack().map((levels, i) => {
                        return (
                            <div key={i} className={'layout vertical gap'}>
                                {levels.map((variation, j) => {
                                    return (
                                        <AssetImage key={j} id={variation} size={60} uploaded={() => {
                                            executeCommand(new AddImageToPackCommand(id, i, j));
                                        }}/>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ImagePackView;