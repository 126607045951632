import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveImageCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        delete data.images[this.id];
        return true;
    }
}