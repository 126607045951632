import StringInput from "../../elements/stringInput.element";
import {useIntegrator} from "../../integrator.context";
import {useState} from "react";
import {Metadata} from "../../models/remoteConstants.model";
import {MetadataCollection} from "../../commands/metadataValue.command";
import {AddTechAttributeCommand} from "../../commands/addTechAttribute.command";
import {AddRewardMetadata} from "../../commands/addRewardMetadata.command";
import {AddConstantUnitAttribute} from "../../commands/addConstantUnitAttribute.command";
import MetadataElement from "../../elements/metadata.element";
import {RemoveConstantUnitAttribute} from "../../commands/removeConstantUnitAttribute.command";
import {RemoveRewardMetadata} from "../../commands/removeRewardMetadata";
import {RemoveTechAttributeCommand} from "../../commands/removeTechAttribute.command";
import {AddConstantBuildingAttribute} from "../../commands/addConstantBuildingAttribute.command";
import {RemoveConstantBuildingAttribute} from "../../commands/removeConstantsBuildingAttribute.command";

function MetadataEditor() {
    const {state, executeCommand} = useIntegrator();

    return (
        <div className={'layout horizontal stretch flex-grow'} style={{gap: 20, overflowX: 'auto'}}>
            <Column title={'Tech Actions'} attributes={state.techActionAttributes} collection={MetadataCollection.TechActions} add={(uid) => executeCommand(new AddTechAttributeCommand(uid, true))} remove={(uid) => executeCommand(new RemoveTechAttributeCommand(uid, true))}/>
            <Column title={'Tech Passives'} attributes={state.techPassiveAttributes} collection={MetadataCollection.TechPassives} add={(uid) => executeCommand(new AddTechAttributeCommand(uid, false))} remove={(uid) => executeCommand(new RemoveTechAttributeCommand(uid, false))}/>
            <Column title={'Unit Attributes'} attributes={state.unitAttributes} collection={MetadataCollection.Units} add={(uid) => executeCommand(new AddConstantUnitAttribute(uid))} remove={(uid) => executeCommand(new RemoveConstantUnitAttribute(uid))}/>
            <Column title={'Building Attributes'} attributes={state.buildingAttributes} collection={MetadataCollection.Buildings} add={(uid) => executeCommand(new AddConstantBuildingAttribute(uid))} remove={(uid) => executeCommand(new RemoveConstantBuildingAttribute(uid))}/>
            <Column title={'Rewards'} attributes={state.rewards} collection={MetadataCollection.Rewards} add={(uid) => executeCommand(new AddRewardMetadata(uid))} remove={(uid) => executeCommand(new RemoveRewardMetadata(uid))}/>
        </div>
    )
}

function Column(props: {title: string, attributes: Metadata[], collection: MetadataCollection, add(value: string): void, remove(value: string): void}) {
    const [attribute, setAttribute] = useState<string>('');

    return (
        <div className={'layout vertical gap flex-grow'}>
            <p className={'text header box'} style={{padding: 10}}>{props.title}</p>
            <div className={'layout vertical gap flex-grow'} style={{overflowY: 'auto', height: 0}}>
                {props.attributes !== undefined && props.attributes !== null && props.attributes.map((metadata, index) => {
                    return <MetadataElement key={index} metadata={metadata} collection={props.collection} onDelete={() => props.remove(metadata.uid)}/>
                })}
            </div>
            <div className={'layout vertical stretch gap'}>
                <StringInput value={attribute} placeholder={'Enter attribute name...'} onChange={setAttribute}/>
                <button onClick={() => {
                    props.add(attribute);
                    setAttribute('');
                }}>
                    Create New
                </button>
            </div>
        </div>
    )
}

export default MetadataEditor;