import {CommandBase} from "./commandBase";
import {RemoteConstantsModel, UnitData} from "../models/remoteConstants.model";

export class UpdateUnitCommand extends CommandBase {
    unit: UnitData;

    constructor(unit: UnitData) {
        super();
        this.unit = unit;
    }

    execute(data: RemoteConstantsModel): boolean {
        const unitIndex = data.units.findIndex(u => u.id === this.unit.id);
        if (unitIndex < 0) {
            return false;
        }

        data.units[unitIndex] = this.unit;
        return true;
    }
}