export function moveObjectUp<T>(arr: T[], objectToMove: T): T[] {
    const index = arr.indexOf(objectToMove);

    if (index <= 0 || index >= arr.length) {
        return arr;
    }

    const newArr = [...arr];
    [newArr[index - 1], newArr[index]] = [newArr[index], newArr[index - 1]];

    return newArr;
}

export function moveObjectDown<T>(arr: T[], objectToMove: T): T[] {
    const index = arr.indexOf(objectToMove);

    if (index < 0 || index >= arr.length - 1) {
        return arr;
    }

    const newArr = [...arr];
    [newArr[index], newArr[index + 1]] = [newArr[index + 1], newArr[index]];

    return newArr;
}