import {CSSProperties, HTMLInputTypeAttribute} from "react";

interface StringInputProps {
    value: string;
    placeholder?: string;
    type?: HTMLInputTypeAttribute;
    style?: CSSProperties;
    onChange(value: string): void;
}

function StringInput(props: StringInputProps) {
    return (
        <input placeholder={props.placeholder} value={props.value} type={props.type}
               onChange={(v) => props.onChange(v.target.value)} style={props.style}/>
    )
}

export default StringInput;