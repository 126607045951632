import {CSSProperties} from "react";
import BooleanInput from "./booleanInput.element";

interface BooleanInputLabelProps {
    title: string;
    value: boolean;
    inputStyle?: CSSProperties;
    onChange(value: boolean): void;
}

function BooleanInputLabel(props: BooleanInputLabelProps) {
    return (
        <div className={'layout horizontal stretch gap'} style={{alignItems: 'center', gap: 10}}>
            <p>{props.title}</p>
            <BooleanInput value={props.value} style={props.inputStyle} onChange={props.onChange}/>
        </div>
    )
}

export default BooleanInputLabel;
