import {CommandBase} from "./commandBase";
import {
    CollectionRequirement,
    CollectionTrigger,
    CollectionType,
    RemoteConstantsModel
} from "../models/remoteConstants.model";
import {v4 as uuidv4} from "uuid";
import {AddImagePackCommand} from "./addImagePack.command";
import {ChangeLocalizationValuesCommand} from "./changeLocalizationValues.command";

export class AddBuildingCommand extends CommandBase {
    execute(data: RemoteConstantsModel): boolean {
        const id = uuidv4();
        data.buildings.push({
            id: id,
            active: true,
            price: 5,
            maxLevel: 0,
            goldPerTurn: 0,
            populationOnCreation: 0,
            collectionTrigger: CollectionTrigger[0],
            collectionType: CollectionType[0],
            collectionRequirement: CollectionRequirement[0],
            turnsUntilFirstTrigger: -1,
            amountIncreasePerTrigger: 0,
            properties: {
                visionRange: 1,
                triggerCadence: 0,
                territoryRange: 0,
                startingSlots: 0
            },
            attributes: [],
            produceableUnits: [],
            rewards: []
        });

        new ChangeLocalizationValuesCommand(id, 'New Building').execute(data);
        new AddImagePackCommand(id).execute(data);
        return true;
    }
}