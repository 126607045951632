import { RemoteConstantsModel } from "../models/remoteConstants.model";
import {CommandBase} from "./commandBase";

export class AddBuildingLevelCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.buildings.findIndex(b => b.id === this.id);
        if (index < 0) {
            return false;
        }

        if (data.buildings[index].rewards === undefined) {
            data.buildings[index].rewards = [];
        }

        data.buildings[index].rewards.push([]);
        return true;
    }
}