import {Metadata, RemoteConstantsModel} from "../models/remoteConstants.model";
import {CommandBase} from "./commandBase";

export enum MetadataValueTypes {
    Number = 'number',
    Entity = 'entity',
    EntityValue = 'entityvalue',
}

export enum MetadataCollection {
    Units,
    Buildings,
    Rewards,
    TechActions,
    TechPassives
}

export enum MetadataValueMethod {
    AddOrUpdate,
    Remove
}

export class MetadataValueCommand extends CommandBase {
    collection: MetadataCollection;
    method: MetadataValueMethod;
    type: MetadataValueTypes;
    uid: string;
    key: string;
    value: number | string;

    constructor(collection: MetadataCollection, method: MetadataValueMethod, type: MetadataValueTypes, uid: string, key: string, value: number | string = 0) {
        super();
        this.collection = collection;
        this.method = method;
        this.type = type;
        this.uid = uid;
        this.key = key;
        this.value = value;
    }

    execute(data: RemoteConstantsModel): boolean {
        if (this.key === '') {
            return false;
        }

        switch (this.collection) {
            case MetadataCollection.Units:
                data.unitAttributes = this.addToCollection(data.unitAttributes);
                return true;
            case MetadataCollection.Buildings:
                data.buildingAttributes = this.addToCollection(data.buildingAttributes);
                return true;
            case MetadataCollection.Rewards:
                data.rewards = this.addToCollection(data.rewards);
                return true;
            case MetadataCollection.TechActions:
                data.techActionAttributes = this.addToCollection(data.techActionAttributes);
                return true;
            case MetadataCollection.TechPassives:
                data.techPassiveAttributes = this.addToCollection(data.techPassiveAttributes);
                return true;
            default:
                return false;
        }
    }

    private addToCollection(attributes: Metadata[]): Metadata[] {
        const index = attributes.findIndex(a => a.uid === this.uid);
        if (index < 0) {
            return attributes;
        }

        switch (this.method) {
            case MetadataValueMethod.AddOrUpdate:
                switch (this.type) {
                    case MetadataValueTypes.Number:
                    case MetadataValueTypes.EntityValue:
                        attributes[index].values[this.key] = this.value as number;
                        break;
                    case MetadataValueTypes.Entity:
                        attributes[index].entities[this.key] = this.value as string;
                        break;
                }
                break;
            case MetadataValueMethod.Remove:
                switch (this.type) {
                    case MetadataValueTypes.Number:
                    case MetadataValueTypes.EntityValue:
                        delete attributes[index].values[this.key];
                        break;
                    case MetadataValueTypes.Entity:
                        delete attributes[index].entities[this.key];
                        break;
                }
                break;
        }

        return attributes;
    }
}