import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {v4 as uuidv4} from "uuid";
import {ChangeLocalizationValuesCommand} from "./changeLocalizationValues.command";
import {AddImagePackCommand} from "./addImagePack.command";

export class AddUnitCommand extends CommandBase {
    execute(data: RemoteConstantsModel): boolean {
        const id = uuidv4();
        data.units.push({
            id: id,
            active: true,
            price: 5,
            properties: {
                visionRange: 1,
                movementSpeed: 1,
                range: 1,
                health: 10,
                attack: 1,
                defense: 1,
                armour: 0,
                regeneration: 2
            },
            attributes: []
        });

        new ChangeLocalizationValuesCommand(id, 'New Unit').execute(data);
        new AddImagePackCommand(id).execute(data);
        return true;
    }
}