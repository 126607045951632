import {useApp} from "../../app.context";
import {useIntegrator} from "../../integrator.context";

function SettingsView() {
    const {state} = useIntegrator();
    const {deleteSecret, setViewSettings, setViewJson} = useApp();

    return (
        <div className={'layout vertical overlay'} style={{color: 'white'}} onClick={() => {
            setViewSettings(false);
        }}>
            <div className={'layout vertical gap box'}
                 style={{padding: 10, gap: 10, width: 300}}
                 onClick={e => e.stopPropagation()}
            >
                <div className={'layout horizontal center stretch'}>
                    <p className={'text header'}>Settings</p>
                    <span className={'fa-solid fa-close'} onClick={() => setViewSettings(false)}/>
                </div>
                <div className={'layout vertical gap'}>
                    <a type="button"
                       href={`data:text/json;charset=utf-8,${encodeURIComponent(
                           JSON.stringify(state)
                       )}`}
                       style={{textAlign: 'center'}}
                       download="remoteConstants.json">
                        Download
                    </a>
                    <button onClick={() => setViewJson(true)}>View JSON</button>
                    <button onClick={deleteSecret}>Sign Out</button>
                    <p style={{textAlign: 'center', color: 'var(--overlay-color)'}}>{`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`}</p>
                </div>
            </div>
        </div>
    )
}

export default SettingsView;