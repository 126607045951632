import {useApp} from "../app.context";
import {useDrag} from "react-dnd";
import AssetImage from "./assetImage.element";

function MetadataDragLabel(props: { uid: string, type: string, draggable(): boolean }) {
    const {getLocalizationValue, setNotification} = useApp();

    const [{isDragging}, drag] = useDrag({
        type: props.type,
        item: {
            id: props.uid,
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
        canDrag: props.draggable()
    });

    const style = {
        opacity: isDragging || !props.draggable() ? 0.5 : 1,
    };

    return (
        <div ref={drag} style={style}>
            <div className={'layout horizontal center stretch gap label'}>
                <div className={'layout horizontal gap center'}>
                    <AssetImage id={props.uid}/>
                    <p style={{width: 160, padding: 5}}
                       onClick={() => setNotification({
                           title: props.uid,
                           message: `${props.uid}.description`,
                           edit: true
                       })}
                    >
                        {getLocalizationValue(props.uid)}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MetadataDragLabel;