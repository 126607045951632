import './styles/App.css';
import './fa/styles/all.css';
import React from 'react';
import {useApp} from "./app.context";
import SecretView from "./features/general/secret.view";
import EditorView from "./features/editor/editor.view";
import LoadingView from "./features/general/loading.view";
import NotificationView from "./features/general/notification.view";
import ConstantsJsonView from "./features/general/constantsJson.view";
import SettingsView from "./features/general/settings.view";
import AddMetadataValueView from "./features/editor/addMetadataValue.view";
import BranchView from "./features/general/branch.view";
import UnitView from "./features/general/unit.view";
import ImagePackView from "./features/general/imagePack.view";
import MarkdownView from "./features/general/markdown.view";

function App() {
    const {secret, loading, imagePackId, markdownFile, metadataValue,
        viewSettings, viewJson, viewBranch, notification, unit} = useApp();

    return (
        <>
            {secret === undefined && <SecretView/>}
            {secret !== undefined && <EditorView/>}
            {markdownFile !== undefined && <MarkdownView/>}
            {imagePackId !== undefined && <ImagePackView/>}
            {metadataValue !== undefined && <AddMetadataValueView/>}
            {viewSettings && <SettingsView/>}
            {viewJson && <ConstantsJsonView/>}
            {viewBranch && <BranchView/>}
            {notification !== undefined && <NotificationView/>}
            {unit !== undefined && <UnitView/>}
            {loading && <LoadingView/>}
        </>
    );
}

export default App;
