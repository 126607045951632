import {useIntegrator} from "../../integrator.context";
import {RemoteConstantsModel, UnitData} from "../../models/remoteConstants.model";
import {useApp} from "../../app.context";
import AssetImage from "../../elements/assetImage.element";

interface CombatResultData {
    attacker: CombatUnitData;
    defender: CombatUnitData;
}

interface CombatantData {
    unit: UnitData;
    health: number;
    attack: number;
    defense: number;
    armour: number;
}

interface CombatUnitData {
    id: string;
    formula: string;
    damageTaken: number;
    defeated: boolean;
    combatant: CombatantData;
}

function CombatSimulation() {
    const {state} = useIntegrator();

    return (
        <div className={'layout vertical gap'} style={{width: 600}}>
            <p className={'text header'}>Combat Simulation</p>
            <div className={'layout vertical gap flex-grow'} style={{overflowY: 'auto', height: 0}}>
                {generateCombinations(state, state.units).map((result, index) => {
                    return <CombatResult key={index} result={result}/>
                })}
            </div>
        </div>
    )
}

function CombatResult(props: { result: CombatResultData}) {
    const {setNotification, getLocalizationValue} = useApp();

    function getFormulaMessage(): string {
        const attacker = props.result.attacker;
        const defender = props.result.defender;
        return `AA = ${attacker.combatant.attack}, AD = ${attacker.combatant.defense}, AR = ${attacker.combatant.armour}, AH = ${attacker.combatant.health}\nDA = ${defender.combatant.attack}, DD = ${defender.combatant.defense}, DR = ${defender.combatant.armour}, DH = ${defender.combatant.health}\n\n${getLocalizationValue(attacker.id)} Damage: ${attacker.formula} = ${defender.damageTaken}\n${getLocalizationValue(defender.id)} Damage: ${defender.formula} = ${attacker.damageTaken}`;
    }

    return (
        <div className={'layout horizontal stretch center box'} style={{gap: 20}}>
            <CombatResultUnit unit={props.result.attacker}/>
            <div style={{backgroundColor: 'var(--overlay-color)', padding: 5, borderRadius: 'var(--border-radius)'}}
                onClick={() => {
                    setNotification({
                        title: 'Formula',
                        message: getFormulaMessage()
                    })
                }}>
                <span className={'fa-solid fa-swords'} style={{fontSize: 22, color: 'white'}}/>
            </div>
            <CombatResultUnit unit={props.result.defender}/>
        </div>
    )
}

function CombatResultUnit(props: {unit: CombatUnitData}) {
    const {getLocalizationValue} = useApp();

    const defeated = props.unit.defeated;
    const passive = props.unit.combatant.unit.attributes.includes('Passive');

    return (
        <div className={'layout horizontal center gap'} style={{width: 300, gap: 10}}>
            <AssetImage id={props.unit.id} upload={false}/>
            <p className={'flex-grow'}>{getLocalizationValue(props.unit.id)}</p>
            {defeated && <span style={{color: 'white'}} className={'fa-solid fa-skull'}/>}
            {!defeated && passive && <span style={{color: 'white'}} className={'fa-solid fa-snooze'}/>}
            <p>{`${props.unit.combatant.health-props.unit.damageTaken}/${props.unit.combatant.health} (${-props.unit.damageTaken})`}</p>
        </div>
    )
}

function generateCombinations(constants: RemoteConstantsModel, units: UnitData[]): CombatResultData[] {
    const activeUnits = units.filter(unit => unit.active);
    const results: CombatResultData[] = [];

    for (let i = 0; i < activeUnits.length; i++) {
        for (let j = 0; j < activeUnits.length; j++) {
            const attackerUnit = activeUnits[i];
            if (attackerUnit.properties.attack <= 0) {
                continue;
            }

            const attacker: CombatantData = {
                unit: attackerUnit,
                health: attackerUnit.properties.health,
                attack: attackerUnit.properties.attack,
                defense: attackerUnit.properties.defense,
                armour: attackerUnit.properties.armour
            }

            const defenderUnit = activeUnits[j];
            const defender: CombatantData = {
                unit: defenderUnit,
                health: defenderUnit.properties.health,
                attack: defenderUnit.properties.attack,
                defense: defenderUnit.properties.defense,
                armour: defenderUnit.properties.armour
            }

            const attackingEvaluation = evaluateCombatDamage(constants, attacker, defender, true);
            const defendingEvaluation = evaluateCombatDamage(constants, attacker, defender, false);

            const attackerDefeated = defendingEvaluation.damage >= attacker.health;
            const defenderDefeated = attackingEvaluation.damage >= defender.health;

            results.push({
                attacker: {
                    id: attackerUnit.id,
                    formula: attackingEvaluation.formula,
                    damageTaken: defenderDefeated ? 0 : defendingEvaluation.damage,
                    defeated: !defenderDefeated && attackerDefeated,
                    combatant: attacker
                },
                defender: {
                    id: defenderUnit.id,
                    formula: defendingEvaluation.formula,
                    damageTaken: attackingEvaluation.damage,
                    defeated: defenderDefeated,
                    combatant: defender
                }
            });
        }
    }

    return results;
}

function evaluateCombatDamage(constants: RemoteConstantsModel, attacker: CombatantData, defender: CombatantData, attacking: boolean): CombatEvaluation {
    let formula = attacking ? constants.attackDamageFormula : constants.retaliatoryDamageFormula;

    formula = formula.replace(/AA/g, `${attacker.attack}`);
    formula = formula.replace(/AD/g, `${attacker.defense}`);
    formula = formula.replace(/AR/g, `${attacker.armour}`);
    formula = formula.replace(/AH/g, `${attacker.health}`);
    formula = formula.replace(/DA/g, `${defender.attack}`);
    formula = formula.replace(/DD/g, `${defender.defense}`);
    formula = formula.replace(/DR/g, `${defender.armour}`);
    formula = formula.replace(/DH/g, `${defender.health}`);

    let damage = 0;
    try {
        damage = eval(formula);
    } catch (e) {}

    if (!attacking && defender.unit.attributes.includes('Passive')) {
        damage = 0;
    }

    return {
        formula: formula,
        damage: Math.max(0, Math.round(damage))
    };
}

interface CombatEvaluation {
    formula: string;
    damage: number;
}

export default CombatSimulation;