import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveTechAttributeCommand extends CommandBase {
    uid: string;
    action: boolean;

    constructor(uid: string, action: boolean) {
        super();
        this.uid = uid;
        this.action = action;
    }

    execute(data: RemoteConstantsModel): boolean {
        const collection = this.action ? data.techActionAttributes : data.techPassiveAttributes;
        const index = collection.findIndex(c => c.uid === this.uid);
        if (index < 0) {
            return false;
        }

        collection.splice(index, 1);
        return true;
    }
}