function WelcomeView() {
    return (
        <div className={'layout vertical flex-grow'} style={{gap: 10, height: 0, overflowY: 'auto'}}>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Introduction</p>
                <p>This website manages configurations for the game <b>Rift</b>. The goal is to have all game design
                    related content here.</p>
                <p>The data is uploaded to our server and served to the game client as a JSON object.</p>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>How to Play</p>
                <p>Click on one of the links below to play the public version of the game.</p>
                <p>On <b>Production</b> you can create your own user and play against other players.</p>
                <p>On <b>Test</b> you can quickly start a local game to test features and configurations.</p><br/>
                <div className={'layout horizontal gap'}>
                    <button onClick={() => window.open('https://rift.xp3games.com')}>Production</button>
                    <button onClick={() => window.open('https://rift-test.xp3games.com')}>Test</button>
                </div>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Access Levels</p>
                <p>You have one of the following access levels depending on the password you used.</p>
                <p>Press on the label on the top-right corner to open the settings window and sign out.</p><br/>
                <p><b>Guest:</b> You are allowed to read data from all branches.</p>
                <p><b>Tester:</b> You are allowed to create branches and change data on all branches except 'main'.</p>
                <p><b>Admin:</b> You are god.</p>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Export Changes</p>
                <p>All changes need to be exported in order to test them on the game client.</p>
                <p>On the top-right corner of the screen, press the <b>Export</b> button to upload your changes to the
                    selected branch.</p>
                <img alt={'img'} className={'preview-img'} src={'./assets/preview_export.png'}/>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Select Branch</p>
                <p>Click on the dropdown next to the <b>Export</b> button to view all branches. Select the one you wish
                    to change to.</p>
                <p>During the game, click on the <b>Debug</b> button (marked red). Then click on the dropdown to select
                    the
                    branch.</p>
                <div className={'layout horizontal gap'}>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_select_branch.png'}/>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_select_branch_client_1.png'}/>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_select_branch_client_2.png'}/>
                </div>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Create Branches</p>
                <p>Click on the <b>+</b> button next to the dropdown to open the branches window.</p>
                <p>Enter a unique name for your branch and press <b>Add</b>.</p>
                <div className={'layout horizontal gap'}>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_create_branch_1.png'}/>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_create_branch_2.png'}/>
                </div>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Update Image Files</p>
                <p>Supported file types: <b>PNG</b></p>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Update Sound Files</p>
                <p>Supported file types: <b>WAV</b></p>
            </div>
        </div>
    )
}

export default WelcomeView;