import {CommandBase} from "./commandBase";
import {RemoteConstantsModel, SoundMetadata} from "../models/remoteConstants.model";

export class UpdateSoundCommand extends CommandBase {
    id: string;
    importSettings: SoundMetadata;

    constructor(id: string, importSettings: SoundMetadata) {
        super();
        this.id = id;
        this.importSettings = importSettings;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.sounds[this.id] = this.importSettings;
        return true;
    }
}