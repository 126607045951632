import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddBuildingAttributeCommand extends CommandBase {
    buildingId: string;
    attribute: string;

    constructor(buildingId: string, attribute: string) {
        super();
        this.buildingId = buildingId;
        this.attribute = attribute;
    }

    execute(state: RemoteConstantsModel): boolean {
        const unitIndex = state.buildings.findIndex(u => u.id === this.buildingId);
        if (unitIndex < 0) {
            return false;
        }

        if (state.buildings[unitIndex].attributes.find(a => a === this.attribute) !== undefined) {
            return false;
        }

        state.buildings[unitIndex].attributes.push(this.attribute);
        return true;
    }
}