import {useApp} from "../../app.context";
import MarkdownPreview from '@uiw/react-markdown-preview';
import {useEffect, useState} from "react";

function MarkdownView() {
    const {markdownFile, setMarkdownFile} = useApp();
    const [markdown, setMarkdown] = useState<string | undefined>(undefined);

    useEffect(() => {
        fetch(`docs/${markdownFile}`)
            .then((response) => response.text())
            .then((text) => setMarkdown(text))
            .catch((error) => console.error('Error loading markdown:', error));
    }, [markdownFile]);

    return (
        <div className={'layout vertical overlay'} onClick={() => setMarkdownFile(undefined)}>
            {markdown !== undefined && <MarkdownPreview source={markdown} style={{
                backgroundColor: 'var(--box-color)',
                color: 'white',
                overflowY: 'auto',
                margin: 20,
                borderRadius: 'var(--border-radius)',
                padding: 20,
                flexGrow: 1,
                height: 0,
                width: '60%'
            }}/>}
        </div>
    )
}

export default MarkdownView;