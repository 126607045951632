import {apiBindings} from "../../utils/constants";
import {useIntegrator} from "../../integrator.context";
import {RemoveFileArchiveCommand} from "../../commands/removeFileArchive.command";

function ArchiveView() {
    const {state, executeCommand} = useIntegrator();

    return (
        <div className={'layout vertical'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout horizontal gap'} style={{flexWrap: 'wrap', alignContent: 'flex-start', gap: 10, flexGrow: 1, overflow: 'auto', height: 0}}>
                {state.fileArchive !== undefined && state.fileArchive.map((fileName, index) => {
                    const url = `${apiBindings.images}${fileName}`;
                    return (
                        <div key={index} style={{position: 'relative', width: 200, height: 200}}>
                            <img alt={fileName} style={{
                                position: 'absolute',
                                backgroundColor: 'var(--box-color)',
                                borderRadius: 5,
                                width: '100%',
                                height: '100%'
                            }}
                                 src={url}
                            />
                            <div style={{
                                position: 'absolute',
                                borderRadius: 5,
                                width: '100%',
                                height: '100%'
                            }} onClick={() => window.open(url)}>
                                <span className={'fa-solid fa-trash'} style={{color: 'white', padding: 10, fontSize: 20}}
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          executeCommand(new RemoveFileArchiveCommand(fileName));
                                      }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ArchiveView;