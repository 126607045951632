import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveConstantBuildingAttribute extends CommandBase {
    uid: string;

    constructor(uid: string) {
        super();
        this.uid = uid;
    }

    execute(state: RemoteConstantsModel): boolean {
        if (state.buildingAttributes === undefined) {
            return false;
        }

        const index = state.buildingAttributes.findIndex(a => a.uid === this.uid);
        if (index < 0) {
            return false;
        }

        state.buildingAttributes.splice(index, 1);
        return true;
    }
}