import { RemoteConstantsModel } from "../models/remoteConstants.model";
import {CommandBase} from "./commandBase";

export class RemoveBuildingLevelCommand extends CommandBase {
    id: string;
    level: number;

    constructor(id: string, level: number) {
        super();
        this.id = id;
        this.level = level;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.buildings.findIndex(b => b.id === this.id);
        if (index < 0) {
            return false;
        }

        data.buildings[index].rewards.splice(this.level, 1);
        return true;
    }
}