import {CommandBase} from "./commandBase";
import {BuildingData, RemoteConstantsModel} from "../models/remoteConstants.model";

export class UpdateBuildingCommand extends CommandBase {
    building: BuildingData;

    constructor(building: BuildingData) {
        super();
        this.building = building;
    }

    execute(data: RemoteConstantsModel): boolean {
        const unitIndex = data.buildings.findIndex(u => u.id === this.building.id);
        if (unitIndex < 0) {
            return false;
        }

        data.buildings[unitIndex] = this.building;
        return true;
    }
}