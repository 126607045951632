import {Vector2} from "../models/math.model";
import NumberInput from "./numberInput.element";
import {useState} from "react";

interface Vector2InputProps {
    title: string;
    value: Vector2;
    onChange(value: Vector2): void;
}

function Vector2Input(props: Vector2InputProps) {
    const [x, setX] = useState<number>(props.value.x);
    const [y, setY] = useState<number>(props.value.y);

    function changeX(value: number) {
        setX(value);
        props.onChange({x: value, y: y});
    }

    function changeY(value: number) {
        setY(value);
        props.onChange({x: x, y: value});
    }

    return (
        <div className={'layout horizontal stretch gap'} style={{alignItems: 'center', gap: 10}}>
            <p>{props.title}</p>
            <div className={'layout horizontal gap'}>
                <NumberInput style={{width: 60}} value={x} onChange={changeX}/>
                <NumberInput style={{width: 60}} value={y} onChange={changeY}/>
            </div>
        </div>
    )
}

export default Vector2Input;