import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {v4 as uuidv4} from "uuid";
import {ChangeLocalizationValuesCommand} from "./changeLocalizationValues.command";
import {AddImagePackCommand} from "./addImagePack.command";

export class AddResourceCommand extends CommandBase {
    execute(data: RemoteConstantsModel): boolean {
        const id = uuidv4();
        data.resources ??= [];
        data.resources.push({
            id: id,
            active: true,
            visionModifier: 0,
            startCount: 0,
            rarity: 100,
            attributes: []
        });

        new ChangeLocalizationValuesCommand(id, 'New Resource').execute(data);
        new AddImagePackCommand(id).execute(data);
        return true;
    }
}