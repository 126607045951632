import {useApp} from "../app.context";
import AssetImage from "./assetImage.element";

interface AttributeLabelProps {
    uid: string;
    onDelete?(): void;
}

function MetadataLabel(props: AttributeLabelProps) {
    const {setNotification, getLocalizationValue} = useApp();

    return (
        <div className={'layout horizontal center stretch gap label'}>
            <div className={'layout horizontal gap center'}>
                <AssetImage id={props.uid}/>
                <p style={{width: 160, padding: 5}}
                   onClick={() => setNotification({title: props.uid, message: `${props.uid}.description`, edit: true})}
                >
                    {getLocalizationValue(props.uid)}
                </p>
            </div>
            {props.onDelete && <span className={'fa-solid fa-trash'} style={{color: 'white'}} onClick={(e) => {
                e.stopPropagation();
                if (props.onDelete) {
                    props.onDelete();
                }
            }}/>}
        </div>
    )
}

export default MetadataLabel;