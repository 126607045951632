import StringInput from "./stringInput.element";
import {CSSProperties, HTMLInputTypeAttribute} from "react";

interface StringInputLabelProps {
    title: string;
    value: string;
    placeholder?: string;
    type?: HTMLInputTypeAttribute;
    inputStyle?: CSSProperties;
    onChange(value: string): void;
}

function StringInputLabel(props: StringInputLabelProps) {
    return (
        <div className={'layout horizontal stretch gap'} style={{alignItems: 'center', gap: 10}}>
            <p>{props.title}</p>
            <StringInput value={props.value} placeholder={props.placeholder} type={props.type}
                         style={props.inputStyle} onChange={props.onChange}/>
        </div>
    )
}

export default StringInputLabel;
