import {useEffect, useState} from "react";
import {useApp} from "../../app.context";
import {useIntegrator} from "../../integrator.context";

function SecretView() {
    const {refreshBranches} = useIntegrator();
    const {setLoading, validateCachedSecret, validateSecret} = useApp();
    const [secret, setCurrentSecret] = useState<string>('');

    useEffect(() => {
        checkCachedSecret();
    }, []);

    async function checkCachedSecret() {
        setLoading(true);
        await refreshBranches();
        await validateCachedSecret();
        setLoading(false);
    }

    async function changeSecret() {
        await validateSecret(secret);
    }

    return (
        <div className={'layout vertical center gap'} style={{height: '100vh'}}>
            <div className={'layout vertical stretch'} style={{width: '260px', gap: 10}}>
                <p className={'text header'}>Welcome to Rift</p>
                <div className={'layout horizontal gap'}>
                    <input className={'flex-grow'} type={'password'} onChange={v => setCurrentSecret(v.target.value)}
                           placeholder={'Enter password...'}/>
                    <button onClick={changeSecret}>
                        <span className={'fa-solid fa-sign-in'}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SecretView;