import {useApp} from "../../app.context";
import {useDrag} from "react-dnd";
import AssetImage from "../../elements/assetImage.element";
import {useIntegrator} from "../../integrator.context";

function TechUnlock(props: { nodeId: string, id: string, cancelDrag(): void }) {
    const {state} = useIntegrator();
    const {setNotification, setUnit} = useApp();

    const [{isDragging}, drag] = useDrag({
        type: 'entity',
        item: {
            nodeId: props.nodeId,
            id: props.id
        },
        end: () => {
            props.cancelDrag();
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        }
    });

    function onClick() {
        const unit = state.units.find(u => u.id === props.id);
        if (unit !== undefined) {
            setUnit(unit.id);
        } else {
            setNotification({
                title: props.id,
                message: `${props.id}.description`,
                edit: true
            });
        }
    }

    return (
        <div ref={drag}
             style={{opacity: isDragging ? 0.5 : 1}}
             onClick={onClick}>
            <AssetImage id={props.id} size={30} upload={false}/>
        </div>
    )
}

export default TechUnlock;