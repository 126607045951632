import {useApp} from "../../app.context";
import {useState} from "react";
import {useIntegrator} from "../../integrator.context";
import {AddSoundCommand} from "../../commands/addSound.command";
import {RemoveSoundCommand} from "../../commands/removeSound.command";
import SoundAsset from "../../elements/soundAsset";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {UpdateSoundCommand} from "../../commands/updateSound.command";

function SoundsEditorView() {
    const {state, executeCommand} = useIntegrator();
    const {setNotification, getLocalizationValue} = useApp();
    const [newKey, setNewKey] = useState<string>('');

    function addNewSound() {
        if (newKey === '') {
            setNotification({
                title: 'Invalid Input',
                message: 'Please enter a valid key.'
            })
            return;
        }

        if (state.sounds !== undefined && state.sounds !== null && newKey in state.sounds) {
            setNotification({
                title: 'Key Already Exists',
                message: 'Please enter a new key.'
            })
            return;
        }

        executeCommand(new AddSoundCommand(newKey));
        setNewKey('');
    }

    return (
        <div className={'layout vertical'} style={{gap: 10, flexGrow: 1}}>
            <div className={'grid two-columns gap'} style={{gap: 10, flexGrow: 1, overflow: 'auto', height: 0}}>
                {state.sounds !== undefined && state.sounds !== null && Object.keys(state.sounds).map((key, index) => {
                    const value = state.sounds[key];
                    return (
                        <div key={index} className={'box'}>
                            <div className={'layout horizontal gap'} style={{gap: 20}}>
                                <SoundAsset id={key} size={80}/>
                                <div className={'layout horizontal gap'} style={{alignItems: 'flex-start'}}>
                                    <p style={{width: 200}}>{getLocalizationValue(key)}</p>
                                    <button onClick={() => {
                                        executeCommand(new RemoveSoundCommand(key));
                                    }}>
                                        <span className={'fa-solid fa-trash'}/>
                                    </button>
                                </div>
                                <div className={'layout vertical gap'}>
                                    <p className={'text header'}>Import Settings</p>
                                    <NumberInputLabel title={'Channels'} value={value.channels} onChange={v => {
                                        value.channels = v;
                                        executeCommand(new UpdateSoundCommand(key, value));
                                    }}/>
                                    <NumberInputLabel title={'Frequency'} value={value.frequency} onChange={v => {
                                        value.frequency = v;
                                        executeCommand(new UpdateSoundCommand(key, value));
                                    }}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <input placeholder={'Enter key...'} value={newKey} onChange={v => setNewKey(v.target.value)}/>
            <button onClick={addNewSound}>Add New Sound</button>
        </div>
    )
}

export default SoundsEditorView;