import { RemoteConstantsModel } from "../models/remoteConstants.model";
import {CommandBase} from "./commandBase";

export class RemoveBuildingRewardCommand extends CommandBase {
    id: string;
    rewardUid: string;
    level: number;

    constructor(id: string, rewardUid: string, level: number) {
        super();
        this.id = id;
        this.rewardUid = rewardUid;
        this.level = level;
    }

    execute(data: RemoteConstantsModel): boolean {
        const buildingI = data.buildings.findIndex(b => b.id === this.id);
        if (buildingI < 0) {
            return false;
        }

        const index = data.buildings[buildingI].rewards[this.level].findIndex(r => r === this.rewardUid);
        if (index < 0) {
            return false;
        }

        data.buildings[buildingI].rewards[this.level].splice(index, 1);
        return true;
    }
}