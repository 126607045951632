import {useApp} from "../../app.context";
import LocalizationInput from "../../elements/localizationInput.element";
import LocalizationTextArea from "../../elements/localizationTextArea.element";

function NotificationView() {
    const {notification, setNotification, getLocalizationValue} = useApp();

    if (notification?.edit === true) {
        return (
            <div className={'layout vertical overlay'} onClick={() => {
                setNotification(undefined);
            }}>
                <div className={'layout vertical gap box'} style={{padding: 10, gap: 10, width: 400}}
                     onClick={e => e.stopPropagation()}
                >
                    <LocalizationInput id={notification?.title ?? ''}/>
                    <LocalizationTextArea id={notification?.message ?? ''} style={{height: '100px'}}/>
                    <button onClick={() => setNotification(undefined)}>Okay!</button>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'layout vertical overlay'} onClick={() => {
                setNotification(undefined);
            }}>
                <div className={'layout vertical gap box'} style={{padding: 10, gap: 10, width: 400}}
                     onClick={e => e.stopPropagation()}
                >
                    <p className={'text header'}>{getLocalizationValue(notification?.title ?? '')}</p>
                    <p style={{whiteSpace: 'pre-wrap'}}>{getLocalizationValue(notification?.message ?? '')}</p>
                    <button onClick={() => setNotification(undefined)}>Close</button>
                </div>
            </div>
        )
    }
}

export default NotificationView;