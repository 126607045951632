import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {RemoveLocalizationValueCommand} from "./removeLocalizationValue.command";

export class RemoveTechNodeCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.techTree.findIndex(u => u.id === this.id);
        if (index < 0) {
            return false;
        }

        for (let i = 0; i < data.techTree[index].children.length; i++) {
            new RemoveTechNodeCommand(data.techTree[index].children[i]).execute(data);
        }

        for (let i = 0; i < data.techTree.length; i++) {
            const cIndex = data.techTree[i].children.indexOf(this.id);
            if (cIndex >= 0) {
                data.techTree[i].children.splice(cIndex, 1);
            }
        }

        data.techTree.splice(index, 1);
        new RemoveLocalizationValueCommand(this.id).execute(data);
        new RemoveLocalizationValueCommand(`${this.id}.description`).execute(data);

        return true;
    }
}