import {AddBuildingCommand} from "../../commands/addBuilding.command";
import {RemoveBuildingCommand} from "../../commands/removeBuilding.command";
import {UpdateBuildingCommand} from "../../commands/updateBuilding.command";
import {useIntegrator} from "../../integrator.context";
import {AddBuildingAttributeCommand} from "../../commands/addBuildingAttribute.command";
import {RemoveBuildingAttributeCommand} from "../../commands/removeBuildingAttribute.command";
import {CollectionRequirement, CollectionTrigger, CollectionType, Metadata} from "../../models/remoteConstants.model";
import {DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import LocalizationInput from "../../elements/localizationInput.element";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import MetadataLabel from "../../elements/metadataLabel.element";
import EntityLabel from "../../elements/entityLabel";
import BooleanInput from "../../elements/booleanInput.element";
import {ChangeConstantsCommand} from "../../commands/changeConstants.command";
import {moveObjectDown, moveObjectUp} from "../../utils/array.utils";
import IconButton from "../../elements/IconButton";
import EntityDragLabel from "../../elements/entityDragLabel";
import ImagePackElement from "../../elements/imagePack.element";
import AssetImage from "../../elements/assetImage.element";
import Selectable from "../../elements/selectable.element";
import SelectableLabel from "../../elements/selectable.label";

function BuildingsEditorView() {
    const {state, executeCommand} = useIntegrator();

    return (
        <EntityEditor name={'Building'} entities={state.buildings.map((building): EntityProps => {
            return {
                id: building.id,
                statistics: [
                    {
                        name: 'Price in Gold',
                        value: building.price,
                        onChange(value: number) {
                            building.price = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Max Level',
                        value: building.maxLevel,
                        onChange(value: number) {
                            building.maxLevel = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Vision Range',
                        value: building.properties.visionRange,
                        onChange(value: number) {
                            building.properties.visionRange = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Territory Range',
                        value: building.properties.territoryRange,
                        onChange(value: number) {
                            building.properties.territoryRange = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Starting Slots',
                        value: building.properties.startingSlots,
                        onChange(value: number) {
                            building.properties.startingSlots = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Gold Reward',
                        value: building.goldPerTurn,
                        onChange(value: number) {
                            building.goldPerTurn = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Population Reward',
                        value: building.populationOnCreation,
                        onChange(value: number) {
                            building.populationOnCreation = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Reward Frequency',
                        value: building.properties.triggerCadence,
                        onChange(value: number) {
                            building.properties.triggerCadence = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Turns Until First Trigger',
                        value: building.turnsUntilFirstTrigger,
                        onChange(value: number) {
                            building.turnsUntilFirstTrigger = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    },
                    {
                        name: 'Amount Increase Per Trigger',
                        value: building.amountIncreasePerTrigger,
                        onChange(value: number) {
                            building.amountIncreasePerTrigger = value;
                            executeCommand(new UpdateBuildingCommand(building));
                        }
                    }
                ],
                attributes: building.attributes
            }
        })}
                      attributes={state.buildingAttributes}
                      addNewEntity={() => executeCommand(new AddBuildingCommand())}
                      deleteEntity={(id) => executeCommand(new RemoveBuildingCommand(id))}
                      deleteEntityAttribute={(id, attribute) => executeCommand(new RemoveBuildingAttributeCommand(id, attribute))}
                      addEntityAttribute={(id, attribute) => executeCommand(new AddBuildingAttributeCommand(id, attribute))}/>
    )
}

interface EntityEditorProps {
    name: string;
    entities: EntityProps[];
    attributes: Metadata[];
    addNewEntity(): void;
    deleteEntity(id: string): void;
    addEntityAttribute(id: string, attribute: string): void;
    deleteEntityAttribute(id: string, attribute: string): void;
}

export interface EntityProps {
    id: string;
    statistics: StatisticProps[];
    attributes: string[];
}

interface StatisticProps {
    name: string;
    value: number;
    onChange(value: number): void;
}

function EntityEditor(props: EntityEditorProps) {
    const {state} = useIntegrator();

    return (
        <div className={'layout horizontal'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout vertical gap'} style={{flexGrow: 1, gap: 10}}>
                <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', gap: 10, height: 0}}>
                    {props.entities.map((entity, i) => {
                        return <EntityItem key={i} entity={entity} parent={props}/>
                    })}
                </div>
                <button onClick={props.addNewEntity}>{`Create new ${props.name}`}</button>
            </div>
            <div className={'layout vertical'} style={{gap: 10}}>
                <div className={'layout vertical gap box flex-grow'}>
                    <p className={'text header'}>{`Attributes`}</p>
                    <div className={'layout vertical flex-grow gap'}
                         style={{flexGrow: 1, overflowY: 'auto', width: '25vw', height: 0}}>
                        {state.buildingAttributes !== undefined && state.buildingAttributes !== null && state.buildingAttributes.map((m, i) => {
                            return <MetadataDragLabel key={i} uid={m.uid} index={i}/>
                        })}
                    </div>
                </div>
                <div className={'layout vertical gap box flex-grow'}>
                    <p className={'text header'}>{`Units`}</p>
                    <div className={'layout vertical flex-grow gap'}
                         style={{flexGrow: 1, overflowY: 'auto', width: '25vw', height: 0}}>
                        {state.units !== undefined && state.units !== null && state.units.map((unit, i) => {
                            return <EntityDragLabel key={i} id={unit.id} type={'unit'}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

function EntityItem(props: { entity: EntityProps, parent: EntityEditorProps }) {
    const {state, executeCommand} = useIntegrator();
    const deleteDisabled = props.parent.entities.length <= 1;

    const building = state.buildings.find(b => b.id === props.entity.id);

    function checkCanDrop(monitor: DropTargetMonitor<any, void>): boolean {
        if (!monitor.isOver({shallow: true})) {
            return false;
        }

        if (building === undefined) {
            return false;
        }

        const type = monitor.getItemType();
        const item = monitor.getItem();
        switch (type) {
            case 'attribute':
                return !building.attributes?.includes(item.attribute);
            case 'unit':
                return !building.produceableUnits?.includes(item.id);
            default:
                return false;
        }
    }

    const [{canDrop}, drop] = useDrop({
        accept: ['attribute', 'unit'],
        drop: (item: any, monitor) => {
            if (building === undefined || !checkCanDrop(monitor)) {
                return;
            }

            switch (monitor.getItemType()) {
                case 'attribute':
                    building.attributes ??= [];
                    building.attributes.push(item.attribute);
                    break;
                case 'unit':
                    building.produceableUnits ??= [];
                    building.produceableUnits.push(item.id);
                    break;
                default:
                    return;
            }

            executeCommand(new UpdateBuildingCommand(building));
        },
        collect: (monitor) => {
            return {
                canDrop: checkCanDrop(monitor)
            };
        },
    });

    if (building === undefined) {
        return <></>
    }

    return (
        <div ref={drop} className={'layout horizontal gap box'} style={{
            gap: 20,
            opacity: building.active ? 1 : 0.5,
            border: `solid 1px ${canDrop ? 'var(--tint-color)' : 'var(--overlay-color)'}`
        }}
        >
            <ImagePackElement id={props.entity.id} size={80}/>
            <AssetImage id={`${props.entity.id}_passive`}/>
            <div className={'layout vertical gap'}>
                <div className={'layout horizontal gap'}>
                    <LocalizationInput style={{width: 100}} id={props.entity.id}/>
                    <BooleanInput value={building.active} onChange={(value) => {
                        building.active = value;
                        executeCommand(new UpdateBuildingCommand(building));
                    }}/>
                    <IconButton icon={'trash'} style={{opacity: deleteDisabled ? 0.5 : 1}} onClick={() => {
                        if (!deleteDisabled) {
                            props.parent.deleteEntity(props.entity.id);
                        }
                    }}/>
                    <IconButton icon={'arrow-down'} onClick={() => {
                        executeCommand(new ChangeConstantsCommand(data => {
                            data.buildings = moveObjectDown(data.buildings, building);
                        }));
                    }}/>
                    <IconButton icon={'arrow-up'} onClick={() => {
                        executeCommand(new ChangeConstantsCommand(data => {
                            data.buildings = moveObjectUp(data.buildings, building);
                        }));
                    }}/>
                </div>
            </div>
            <div className={'layout vertical gap'}>
                <p className={'text header'}>Statistics</p>
                {props.entity.statistics.map((statistic, i) => {
                    return <NumberInputLabel key={i} title={statistic.name} style={{flexGrow: 'unset'}}
                                             value={statistic.value}
                                             onChange={statistic.onChange}/>
                })}
                <SelectableLabel name={'Collection Trigger'} value={building.collectionTrigger}
                                 options={CollectionTrigger} onChange={value => {
                    if (building === undefined) {
                        return;
                    }

                    building.collectionTrigger = value;
                    executeCommand(new UpdateBuildingCommand(building));
                }}/>
                <SelectableLabel name={'Collection Type'} value={building.collectionType} options={CollectionType}
                                 onChange={value => {
                                     if (building === undefined) {
                                         return;
                                     }

                                     building.collectionType = value;
                                     executeCommand(new UpdateBuildingCommand(building));
                                 }}/>
                <SelectableLabel name={'Collection Requirement'} value={building.collectionRequirement}
                                 options={CollectionRequirement} onChange={value => {
                    if (building === undefined) {
                        return;
                    }

                    building.collectionRequirement = value;
                    executeCommand(new UpdateBuildingCommand(building));
                }}/>
            </div>
            <div className={'layout vertical gap flex-grow'}>
                <div className={'layout vertical gap'}
                     style={{
                         borderRadius: '5px',
                         flexGrow: 1
                     }}>
                    <p className={'text header'}>Attributes, Produceable Units</p>
                    {props.entity.attributes.map((uid, i) => {
                        return <MetadataLabel key={i} uid={uid} onDelete={() => {
                            props.parent.deleteEntityAttribute(props.entity.id, uid);
                        }}/>
                    })}
                    {building?.produceableUnits !== undefined && building?.produceableUnits !== null && building.produceableUnits.map((unit, i) => {
                        return <EntityLabel key={i} id={unit} onDelete={() => {
                            if (building === undefined) {
                                return;
                            }

                            building.produceableUnits = building.produceableUnits.filter(t => t !== unit);
                            executeCommand(new UpdateBuildingCommand(building));
                        }}/>
                    })}
                </div>
            </div>
        </div>
    )
}

function MetadataDragLabel(props: { uid: string, index: number }) {
    const [{opacity}, drag] = useDrag({
        type: 'attribute',
        item: {
            attribute: props.uid,
        },
        collect: (monitor) => {
            const isDragging = monitor.isDragging();
            const opacity = isDragging ? 0.5 : 1;
            return {
                opacity: opacity,
            };
        },
    });

    const style = {
        opacity: opacity,
    };

    return (
        <div ref={drag} style={style}>
            <MetadataLabel uid={props.uid}/>
        </div>
    );
}

export default BuildingsEditorView;