import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class ChangeConstantsCommand extends CommandBase {
    change: (data: RemoteConstantsModel) => void;

    constructor(change: (data: RemoteConstantsModel) => void) {
        super();
        this.change = change;
    }

    execute(data: RemoteConstantsModel): boolean {
        this.change(data);
        return true;
    }
}