import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {SetAttributeLocalizationCommand} from "./setAttributeLocalization.command";
import {v4 as uuidv4} from "uuid";

export class AddRewardMetadata extends CommandBase {
    id: string;

    constructor(attribute: string) {
        super();
        this.id = attribute;
    }

    execute(state: RemoteConstantsModel): boolean {
        if (this.id === '') {
            return false;
        }

        if (state.rewards === undefined) {
            state.rewards = [];
        }

        const uid = uuidv4();
        new SetAttributeLocalizationCommand(uid, this.id).execute(state);

        state.rewards.push({
            uid: uid,
            id: this.id,
            values: {},
            entities: {}
        });
        return true;
    }
}