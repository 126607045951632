import {CommandBase} from "./commandBase";
import {RemoteConstantsModel, TileData} from "../models/remoteConstants.model";

export class UpdateTileCommand extends CommandBase {
    tile: TileData;

    constructor(tile: TileData) {
        super();
        this.tile = tile;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.tiles.findIndex(u => u.id === this.tile.id);
        if (index < 0) {
            return false;
        }

        data.tiles[index] = this.tile;
        return true;
    }
}