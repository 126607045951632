import NumberInputLabel from "../../elements/numberInputLabel.element";
import StringInputLabel from "../../elements/stringInputLabel.element";
import {StartingUnitCommand} from "../../commands/startingUnit.command";
import {StartingBuildingCommand} from "../../commands/startingBuilding.command";
import {useIntegrator} from "../../integrator.context";
import {ChangeConstantsCommand} from "../../commands/changeConstants.command";
import Selectable from "../../elements/selectable.label";
import {NoiseMap} from "../../models/remoteConstants.model";
import CombatSimulation from "./combatSimulation.view";
import SelectableLabel from "../../elements/selectable.label";

function GeneralEditorView() {
    const {state, executeCommand} = useIntegrator();

    state.elevationNoise ??= {} as NoiseMap;
    state.temperatureNoise ??= {} as NoiseMap;

    return (
        <div className={'layout horizontal stretch flex-grow'} style={{gap: 40}}>
            <div className={'layout vertical gap'} style={{width: 500, gap: 20}}>
                <p className={'text header'}>General</p>
                <div className={'layout vertical gap'}>
                    <SelectableLabel name={'Starting Unit'} value={state.startingUnit} options={state.units.map((b) => b.id)}
                                onChange={value => executeCommand(new StartingUnitCommand(value))}/>
                    <SelectableLabel name={'Starting Building'} value={state.startingBuilding}
                                options={state.buildings.map((b) => b.id)}
                                onChange={value => executeCommand(new StartingBuildingCommand(value))}/>
                    <SelectableLabel name={'Barracks Reference'} value={state.barracksId}
                                options={state.buildings.map((b) => b.id)}
                                onChange={value => executeCommand(new ChangeConstantsCommand(data => data.barracksId = value))}/>
                    <NumberInputLabel title={'Starting Gold'} value={state.startingGold}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.startingGold = v))}/>
                    <NumberInputLabel title={'Starting Gold Per Turn'} value={state.startingGoldPerTurn}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.startingGoldPerTurn = v))}/>
                    <StringInputLabel title={'Attack Damage Formula'} value={state.attackDamageFormula}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.attackDamageFormula = v))}/>
                    <StringInputLabel title={'Retaliatory Damage Formula'} value={state.retaliatoryDamageFormula}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.retaliatoryDamageFormula = v))}/>
                </div>
                <p className={'text header'}>Elevation Noise Map</p>
                <div className={'layout vertical gap'}>
                    <NumberInputLabel title={'Scale'} value={state.elevationNoise.scale}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.elevationNoise.scale = v))}/>
                    <NumberInputLabel title={'Octaves'} value={state.elevationNoise.octaves}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.elevationNoise.octaves = v))}/>
                    <NumberInputLabel title={'Lacunarity'} value={state.elevationNoise.lacunarity}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.elevationNoise.lacunarity = v))}/>
                    <NumberInputLabel title={'Persistance'} value={state.elevationNoise.persistance}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.elevationNoise.persistance = v))}/>
                </div>
                <p className={'text header'}>Temperature Noise Map</p>
                <div className={'layout vertical gap'}>
                    <NumberInputLabel title={'Scale'} value={state.temperatureNoise.scale}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.temperatureNoise.scale = v))}/>
                    <NumberInputLabel title={'Octaves'} value={state.temperatureNoise.octaves}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.temperatureNoise.octaves = v))}/>
                    <NumberInputLabel title={'Lacunarity'} value={state.temperatureNoise.lacunarity}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.temperatureNoise.lacunarity = v))}/>
                    <NumberInputLabel title={'Persistance'} value={state.temperatureNoise.persistance}
                                      onChange={v => executeCommand(new ChangeConstantsCommand(d => d.temperatureNoise.persistance = v))}/>
                </div>
            </div>
            <CombatSimulation/>
        </div>
    )
}

export default GeneralEditorView;