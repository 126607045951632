import StringInput from "./stringInput.element";
import {useApp} from "../app.context";
import {CSSProperties} from "react";
import {useIntegrator} from "../integrator.context";
import {ChangeLocalizationValuesCommand} from "../commands/changeLocalizationValues.command";

interface LocalizationInputProps {
    id: string;
    style?: CSSProperties;
}

function LocalizationInput(props: LocalizationInputProps) {
    const {executeCommand} = useIntegrator();
    const {getLocalizationValue} = useApp();

    return <StringInput value={getLocalizationValue(props.id)} onChange={(value) =>
        executeCommand(new ChangeLocalizationValuesCommand(props.id, value))} style={props.style}
    />
}

export default LocalizationInput;