import {useCallback, useEffect, useRef, useState} from "react";
import {useApp} from "../app.context";
import {defaultImage} from "../utils/constants";
import {inferMimeType} from "../utils/file.utils";

interface SoundAssetProps {
    id: string;
    size?: number;
    upload?: boolean;
}

function SoundAsset(props: SoundAssetProps) {
    const {getSoundUrl, uploadFile} = useApp();
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        loadFile()
    }, []);

    async function loadFile() {
        const url = getSoundUrl(props.id);
        if (url === undefined) {
            return;
        }

        const response = await fetch(url);
        if (!response.ok) {
            return;
        }

        const blob = await response.blob();
        const mimeType = inferMimeType(url);
        if (mimeType === null) {
            return;
        }

        const newBlob = new Blob([blob], {
            type: mimeType
        });

        const reader = new FileReader();
        reader.onload = () => {
            setAudioSrc(reader.result as string);
        };

        reader.readAsDataURL(newBlob);
    }

    const handleDrop = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && file.type.startsWith('audio/')) {
            uploadSound(file);
            const reader = new FileReader();
            reader.onload = () => {
                setAudioSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    async function uploadSound(file: File) {
        if (loading) {
            return;
        }

        if (file === undefined) {
            return;
        }

        setLoading(true);
        await uploadFile(props.id, file);
        setLoading(false);
    }

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    if (audioSrc && !loading) {
        return (
            <div onDrop={handleDrop}
                 onDragOver={handleDragOver}>
                <audio ref={audioRef} src={audioSrc}/>
                <button onClick={handlePlayPause} style={{
                    width: props.size,
                    height: props.size
                }}>
                    {isPlaying ? 'Pause' : 'Play'}
                </button>
            </div>
        )
    } else {
        return (
            <img onDrop={handleDrop}
                 onDragOver={handleDragOver}
                 alt={props.id}
                 src={defaultImage}
                 style={{
                     backgroundColor: 'black',
                     borderRadius: 5,
                     width: props.size,
                     height: props.size
                 }}
            />
        )
    }
}

export default SoundAsset;