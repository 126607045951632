import {Metadata, RemoteConstantsModel} from "../models/remoteConstants.model";
import {CommandBase} from "./commandBase";
import {MetadataCollection} from "./metadataValue.command";

export class MetadataIdCommand extends CommandBase {
    collection: MetadataCollection;
    uid: string;
    id: string;

    constructor(collection: MetadataCollection, uid: string, id: string) {
        super();
        this.collection = collection;
        this.uid = uid;
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        if (this.uid === '' || this.id === '') {
            return false;
        }

        switch (this.collection) {
            case MetadataCollection.Units:
                this.changeInCollection(data.unitAttributes);
                return true;
            case MetadataCollection.Buildings:
                this.changeInCollection(data.buildingAttributes);
                return true;
            case MetadataCollection.Rewards:
                this.changeInCollection(data.rewards);
                return true;
            case MetadataCollection.TechActions:
                this.changeInCollection(data.techActionAttributes);
                return true;
            case MetadataCollection.TechPassives:
                this.changeInCollection(data.techPassiveAttributes);
                return true;
            default:
                return false;
        }
    }

    private changeInCollection(attributes: Metadata[]) {
        const index = attributes.findIndex(a => a.uid === this.uid);
        if (index < 0) {
            return attributes;
        }

        attributes[index].id = this.id;
    }
}