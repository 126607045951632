import {useApp} from "../app.context";

interface ImagePackElementProps {
    id: string;
    size?: number;
    background?: string;
}

function ImagePackElement(props: ImagePackElementProps) {
    const {setImagePackId, getImageUrl} = useApp();

    return (
        <div onClick={() => {
            setImagePackId(props.id)
        }} style={{
            position: 'relative',
            borderRadius: 5,
            width: props.size ?? 50,
            height: props.size ?? 50,
            backgroundColor: props.background ?? 'black',
            border: 'solid 1px #515151',
            boxShadow: '3px 3px #6c6c6c4d, 6px 6px #6c6c6c33'
        }}
        >
            <img alt={props.id} style={{
                borderRadius: 5,
                width: '100%',
                height: '100%'
            }}
                 src={getImageUrl(props.id)}
            />
        </div>
    )
}

export default ImagePackElement;