import {CSSProperties} from "react";

interface TextAreaProps {
    value: string;
    placeholder?: string;
    style?: CSSProperties;
    onChange(value: string): void;
}

function TextArea(props: TextAreaProps) {
    return (
        <textarea placeholder={props.placeholder}
                  value={props.value}
                  style={props.style}
                  onChange={(v) =>
                      props.onChange(v.target.value)}
        />
    )
}

export default TextArea;