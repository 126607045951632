import GeneralEditorView from "./generalEditor.view";
import UnitsEditorView from "./unitsEditor.view";
import React, {useState} from "react";
import BuildingsEditorView from "./buildingsEditor.view";
import LocalizationEditorView from "./localizationEditor.view";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import ImagesEditorView from "./imagesEditor.view";
import ArchiveView from "./archive.view";
import TechTreeEditorView from "../techTree/techTreeEditor.view";
import {useApp} from "../../app.context";
import {useIntegrator} from "../../integrator.context";
import SoundsEditorView from "./soundsEditor.view";
import TilesEditorView from "./tilesEditor.view";
import MetadataEditor from "./metadata.editor";
import ResourcesEditor from "./resources.editor";
import {AccessLevel} from "../../models/accessLevel.model";
import WelcomeView from "./welcome.view";
import RewardsEditor from "./rewards.editor";

enum EditorState {
    Welcome = "Welcome",
    General = "General",
    TechTree = "Tech Tree",
    Units = "Units",
    Buildings = "Buildings",
    Tiles = "Tiles",
    Resources = "Resources",
    Rewards = "Rewards",
    Metadata = "Metadata",
    Images = "Images",
    Sounds = "Sounds",
    Localization = "Localization",
    Archive = "Archive",
}

const editorStateBindings: {type: EditorState, file: string | undefined}[] = [
    {
        type: EditorState.Welcome,
        file: undefined
    },
    {
        type: EditorState.General,
        file: 'general.md'
    },
    {
        type: EditorState.TechTree,
        file: 'tech_tree.md'
    },
    {
        type: EditorState.Units,
        file: 'units.md'
    },
    {
        type: EditorState.Buildings,
        file: 'buildings.md'
    },
    {
        type: EditorState.Tiles,
        file: 'tiles.md'
    },
    {
        type: EditorState.Resources,
        file: 'resources.md'
    },
    {
        type: EditorState.Rewards,
        file: 'rewards.md'
    },
    {
        type: EditorState.Metadata,
        file: 'metadata.md'
    },
    {
        type: EditorState.Images,
        file: 'images.md'
    },
    {
        type: EditorState.Sounds,
        file: 'sounds.md'
    },
    {
        type: EditorState.Localization,
        file: 'localization.md'
    },
    {
        type: EditorState.Archive,
        file: 'archive.md'
    }
]

function EditorView() {
    const {branches, branch, applyBranch, goBack, goForward, canGoBack, canGoForward} = useIntegrator();
    const {accessLevel, setLoading, setMarkdownFile, setViewSettings, setViewBranch, exportRemoteConstants} = useApp();
    const [state, setState] = useState<string>(loadCachedState());

    async function exportData() {
        setLoading(true);
        await exportRemoteConstants();
        setLoading(false);
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={'layout vertical'} style={{height: '100vh'}}>
                <div className={'layout vertical'} style={{padding: 20, gap: 20, flexGrow: 1}}>
                    <div className={'layout horizontal stretch'} style={{height: 40, gap: 20}}>
                        <div className={'layout horizontal center gap'}>
                            {Object.values(editorStateBindings).map((config, i) => {
                                const selected = config.type === state;
                                const hasInfo = config.file !== undefined;
                                return <button key={i}
                                               className={`layout horizontal center gap bar-state ${selected ? 'bar-state-active' : 'bar-state-inactive'}`}
                                               style={{height: '100%'}}
                                               onClick={() => {
                                                   if (selected && hasInfo) {
                                                       setMarkdownFile(config.file);
                                                   } else {
                                                       setState(config.type);
                                                       cacheState(config.type);
                                                   }
                                               }}>{config.type}
                                    {selected && hasInfo && <span className={'fa-solid fa-square-info'}/>}
                                </button>
                            })}
                        </div>
                        <div className={'layout horizontal gap stretch'}>
                            <button onClick={goBack} style={{opacity: `${canGoBack() ? 1 : 0.5}`}}>
                                <span className={'fa-solid fa-undo'}/>
                            </button>
                            <button onClick={goForward} style={{opacity: `${canGoForward() ? 1 : 0.5}`}}>
                                <span className={'fa-solid fa-redo'}/>
                            </button>
                            <select value={branch} defaultValue={branch} onChange={async (v) => {
                                setLoading(true);
                                await applyBranch(v.target.value);
                                setLoading(false);
                            }}>
                                {branches.map((id, index) => {
                                    return <option key={index} value={id}>{id}</option>
                                })}
                            </select>
                            {accessLevel > AccessLevel.Guest && <button onClick={() => setViewBranch(true)}>
                                <span className={'fa-solid fa-add'}/>
                            </button>}
                            {(accessLevel === AccessLevel.Admin || (accessLevel === AccessLevel.Tester && branch !== "main")) &&
                                <button className={'export-button'} onClick={exportData}>
                                    Export
                                </button>
                            }
                            <p onClick={() => setViewSettings(true)} className={'layout vertical center box'}>{AccessLevel[accessLevel]}</p>
                        </div>
                    </div>
                    {state === EditorState.Welcome && <WelcomeView/>}
                    {state === EditorState.General && <GeneralEditorView/>}
                    {state === EditorState.TechTree && <TechTreeEditorView/>}
                    {state === EditorState.Units && <UnitsEditorView/>}
                    {state === EditorState.Buildings && <BuildingsEditorView/>}
                    {state === EditorState.Tiles && <TilesEditorView/>}
                    {state === EditorState.Resources && <ResourcesEditor/>}
                    {state === EditorState.Rewards && <RewardsEditor/>}
                    {state === EditorState.Metadata && <MetadataEditor/>}
                    {state === EditorState.Images && <ImagesEditorView/>}
                    {state === EditorState.Sounds && <SoundsEditorView/>}
                    {state === EditorState.Localization && <LocalizationEditorView/>}
                    {state === EditorState.Archive && <ArchiveView/>}
                </div>
            </div>
        </DndProvider>
    )
}

function loadCachedState(): string {
    const value = localStorage.getItem('features-state');
    return value === null ? EditorState.Welcome : value;
}

function cacheState(state: string) {
    localStorage.setItem('features-state', state);
}

export default EditorView;