import {useApp} from "../../app.context";
import {useState} from "react";
import AssetImage from "../../elements/assetImage.element";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {useIntegrator} from "../../integrator.context";
import {AddImageCommand} from "../../commands/addImage.command";
import {RemoveImageCommand} from "../../commands/removeImage.command";
import {UpdateImageCommand} from "../../commands/updateImage.command";


function ImagesEditorView() {
    const {state, executeCommand} = useIntegrator();
    const {setNotification, getLocalizationValue} = useApp();
    const [newKey, setNewKey] = useState<string>('');

    function addNewImage() {
        if (newKey === '') {
            setNotification({
                title: 'Invalid Input',
                message: 'Please enter a valid key.'
            })
            return;
        }

        if (newKey in state.images) {
            setNotification({
                title: 'Key Already Exists',
                message: 'Please enter a new key.'
            })
            return;
        }

        executeCommand(new AddImageCommand(newKey));
        setNewKey('');
    }

    return (
        <div className={'layout vertical'} style={{gap: 10, flexGrow: 1}}>
            <div className={'grid two-columns gap'} style={{gap: 10, flexGrow: 1, overflow: 'auto', height: 0}}>
                {state.images !== undefined && Object.keys(state.images).map((image, index) => {
                    const value = state.images[image];
                    return (
                        <div key={index} className={'box'}>
                            <div className={'layout horizontal gap'} style={{gap: 20}}>
                                <AssetImage id={image} size={80}/>
                                <div className={'layout horizontal gap'} style={{alignItems: 'flex-start'}}>
                                    <p style={{width: 200}}>{getLocalizationValue(image)}</p>
                                    <button onClick={() => {
                                        executeCommand(new RemoveImageCommand(image));
                                    }}>
                                        <span className={'fa-solid fa-trash'}/>
                                    </button>
                                </div>
                                <div className={'layout vertical gap'}>
                                    <p className={'text header'}>Import Settings</p>
                                    <NumberInputLabel title={'Pixels Per Unit'} value={value.pixelMultiplier} onChange={v => {
                                        value.pixelMultiplier = v;
                                        executeCommand(new UpdateImageCommand(image, value));
                                    }}/>
                                    <NumberInputLabel title={'Pivot X'} value={value.offsetX} onChange={v => {
                                        value.offsetX = v;
                                        executeCommand(new UpdateImageCommand(image, value));
                                    }}/>
                                    <NumberInputLabel title={'Pivot Y'} value={value.offsetY} onChange={v => {
                                        value.offsetY = v;
                                        executeCommand(new UpdateImageCommand(image, value));
                                    }}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <input placeholder={'Enter key...'} value={newKey} onChange={v => setNewKey(v.target.value)}/>
            <button onClick={addNewImage}>Add New Image</button>
        </div>
    )
}

export default ImagesEditorView;