import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddImagePackCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.imagePacks[this.id] = [[this.id]];
        return true;
    }
}