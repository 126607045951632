import {CSSProperties, useState} from "react";

interface BooleanInputProps {
    value: boolean;
    style?: CSSProperties;
    onChange(value: boolean): void;
}

function BooleanInput(props: BooleanInputProps) {
    const [checked, setChecked] = useState<boolean>(props.value);

    return (
        <input type={'checkbox'} checked={checked} onChange={(v) => {
            const newValue = !checked;
            setChecked(newValue)
            props.onChange(newValue);
        }} style={props.style}/>
    )
}

export default BooleanInput;