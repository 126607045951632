import {useDrag} from "react-dnd";
import EntityLabel from "./entityLabel";
import {useApp} from "../app.context";

function EntityDragLabel(props: {id: string, type: string}) {
    const {setNotification} = useApp();

    const [{opacity}, drag] = useDrag({
        type: props.type,
        item: {
            id: props.id,
        },
        collect: (monitor) => {
            const isDragging = monitor.isDragging();
            const opacity = isDragging ? 0.5 : 1;
            return {
                opacity: opacity,
            };
        },
    });

    return (
        <div ref={drag} style={{opacity: opacity}} onClick={() => {
            setNotification({
                title: props.id,
                message: props.id + '.description',
                edit: true
            })
        }}>
            <EntityLabel id={props.id}/>
        </div>
    )
}

export default EntityDragLabel;