import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddUnitAttributeCommand extends CommandBase {
    unitId: string;
    attribute: string;

    constructor(unitId: string, attribute: string) {
        super();
        this.unitId = unitId;
        this.attribute = attribute;
    }

    execute(state: RemoteConstantsModel): boolean {
        const unitIndex = state.units.findIndex(u => u.id === this.unitId);
        if (unitIndex < 0) {
            return false;
        }

        if (state.units[unitIndex].attributes.find(a => a === this.attribute) !== undefined) {
            return false;
        }

        state.units[unitIndex].attributes.push(this.attribute);
        return true;
    }
}