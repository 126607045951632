import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {v4 as uuidv4} from "uuid";
import {ChangeLocalizationValuesCommand} from "./changeLocalizationValues.command";

export class AddTechNodeCommand extends CommandBase {
    parent: string;

    constructor(parent: string) {
        super();
        this.parent = parent;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.techTree.findIndex(u => u.id === this.parent);
        if (index < 0) {
            return false;
        }

        const id = uuidv4();
        data.techTree.push({
            id: id,
            price: 5,
            children: [],
            unlocks: []
        });

        data.techTree[index].children.push(id);
        new ChangeLocalizationValuesCommand(id, 'New Tech').execute(data);
        new ChangeLocalizationValuesCommand(`${id}.description`, 'Default Tech Description').execute(data);

        return true;
    }
}