import {JsonView} from "react-json-view-lite";
import {useApp} from "../../app.context";
import {collapseAllNested, defaultStyles} from 'react-json-view-lite';
import {useIntegrator} from "../../integrator.context";

defaultStyles.expandIcon = 'fa-solid fa-caret-right';
defaultStyles.collapseIcon = 'fa-solid fa-caret-down';

function ConstantsJsonView() {
    const {state} = useIntegrator();
    const {setViewJson} = useApp();

    return (
        <div className={'layout vertical overlay'} style={{color: 'white'}}>
            <div className={'layout vertical box'} style={{width: 600, height: 400, overflowY: 'auto', gap: 10}}>
                <div className={'layout horizontal stretch'}>
                    <p className={'text header'}>Json View</p>
                    <span className={'fa-solid fa-close'} onClick={() => setViewJson(false)}/>
                </div>
                <JsonView data={state} shouldExpandNode={collapseAllNested} style={defaultStyles}/>
            </div>
        </div>
    )
}

export default ConstantsJsonView;