import EntityEditor, {EntityProps} from "./entityEditor";
import {AddUnitCommand} from "../../commands/addUnit.command";
import {UpdateUnitCommand} from "../../commands/updateUnit.command";
import {RemoveUnitCommand} from "../../commands/removeUnit.command";
import {useIntegrator} from "../../integrator.context";
import {AddConstantUnitAttribute} from "../../commands/addConstantUnitAttribute.command";
import {RemoveUnitAttributeCommand} from "../../commands/removeUnitAttribute.command";
import {AddUnitAttributeCommand} from "../../commands/addUnitAttribute.command";
import {RemoveConstantUnitAttribute} from "../../commands/removeConstantUnitAttribute.command";

function UnitsEditorView() {
    const {state, executeCommand} = useIntegrator();

    return (
        <EntityEditor name={'Unit'} entities={state.units.map((unit): EntityProps => {
            return {
                id: unit.id,
                unit: unit,
                statistics: [
                    {
                        name: 'Price in Gold',
                        value: unit.price,
                        onChange(value: number) {
                            unit.price = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Attack',
                        value: unit.properties.attack,
                        onChange(value: number) {
                            unit.properties.attack = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Defense',
                        value: unit.properties.defense,
                        onChange(value: number) {
                            unit.properties.defense = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Armour',
                        value: unit.properties.armour,
                        onChange(value: number) {
                            unit.properties.armour = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Health',
                        value: unit.properties.health,
                        onChange(value: number) {
                            unit.properties.health = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Movement',
                        value: unit.properties.movementSpeed,
                        onChange(value: number) {
                            unit.properties.movementSpeed = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Range',
                        value: unit.properties.range,
                        onChange(value: number) {
                            unit.properties.range = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Vision',
                        value: unit.properties.visionRange,
                        onChange(value: number) {
                            unit.properties.visionRange = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    },
                    {
                        name: 'Regeneration',
                        value: unit.properties.regeneration,
                        onChange(value: number) {
                            unit.properties.regeneration = value;
                            executeCommand(new UpdateUnitCommand(unit));
                        }
                    }
                ],
                attributes: unit.attributes
            }
        })}
                      metadata={state.unitAttributes}
                      addNewEntity={() => executeCommand(new AddUnitCommand())}
                      addNewAttribute={(attribute) => executeCommand(new AddConstantUnitAttribute(attribute))}
                      deleteEntity={(id) => executeCommand(new RemoveUnitCommand(id))}
                      deleteEntityAttribute={(id, attribute) => executeCommand(new RemoveUnitAttributeCommand(id, attribute))}
                      addEntityAttribute={(id, attribute) => executeCommand(new AddUnitAttributeCommand(id, attribute))}
                      deleteAttribute={(attribute) => executeCommand(new RemoveConstantUnitAttribute(attribute))}/>
    )
}

export default UnitsEditorView;