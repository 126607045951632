import AssetImage from "./assetImage.element";
import {useApp} from "../app.context";

interface EntityLabelProps {
    id: string;
    onDelete?(): void;
}

function EntityLabel(props: EntityLabelProps) {
    const {getLocalizationValue} = useApp();

    return (
        <div className={'layout horizontal stretch gap label'} style={{alignItems: 'center', padding: 5}}>
            <div className={'layout horizontal gap'} style={{alignItems: 'center'}}>
                <AssetImage id={props.id}/>
                <p style={{width: 160, padding: 5}}>{getLocalizationValue(props.id)}</p>
            </div>
            {props.onDelete && <span className={'fa-solid fa-trash'} style={{color: 'white', padding: 5}} onClick={(e) => {
                e.stopPropagation();
                if (props.onDelete) {
                    props.onDelete();
                }
            }}/>}
        </div>
    )
}

export default EntityLabel;