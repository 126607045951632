import {useDrag, useDrop} from "react-dnd";
import AssetImage from "../../elements/assetImage.element";
import LocalizationInput from "../../elements/localizationInput.element";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {Metadata, UnitData} from "../../models/remoteConstants.model";
import MetadataLabel from "../../elements/metadataLabel.element";
import BooleanInput from "../../elements/booleanInput.element";
import {UpdateUnitCommand} from "../../commands/updateUnit.command";
import {useIntegrator} from "../../integrator.context";
import {ChangeConstantsCommand} from "../../commands/changeConstants.command";
import {moveObjectDown, moveObjectUp} from "../../utils/array.utils";
import IconButton from "../../elements/IconButton";
import ImagePackElement from "../../elements/imagePack.element";


interface EntityEditorProps {
    name: string;
    entities: EntityProps[];
    metadata: Metadata[];
    addNewEntity(): void;
    addNewAttribute(attribute: string): void;
    deleteEntity(id: string): void;
    deleteAttribute(attribute: string): void;
    addEntityAttribute(id: string, attribute: string): void;
    deleteEntityAttribute(id: string, attribute: string): void;
}

export interface EntityProps {
    id: string;
    unit: UnitData;
    statistics: StatisticProps[];
    attributes: string[];
}

interface StatisticProps {
    name: string;
    value: number;
    onChange(value: number): void;
}

function EntityEditor(props: EntityEditorProps) {
    return (
        <div className={'layout horizontal'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout vertical gap'} style={{flexGrow: 1, gap: 10}}>
                <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', gap: 10, height: 0}}>
                    {props.entities.map((entity, i) => {
                        return <EntityItem key={i} entity={entity} parent={props}/>
                    })}
                </div>
                <button onClick={props.addNewEntity}>{`Create new ${props.name}`}</button>
            </div>
            <div className={'layout vertical gap'} style={{gap: 10}}>
                <div className={'layout vertical gap box'}
                     style={{flexGrow: 1, overflowY: 'auto', height: 0, width: '25vw'}}>
                    <p className={'text header'}>{`Attributes`}</p>
                    {props.metadata !== undefined && props.metadata !== null && props.metadata.map((m, i) => {
                        return <MetadataDragLabel key={i} uid={m.uid} index={i} onDelete={() => {
                            props.deleteAttribute(m.uid);
                        }}/>
                    })}
                </div>
            </div>
        </div>
    )
}

function EntityItem(props: { entity: EntityProps, parent: EntityEditorProps }) {
    const {executeCommand} = useIntegrator();
    const deleteDisabled = props.parent.entities.length <= 1;

    const [{canDrop}, drop] = useDrop({
        accept: 'attribute',
        drop: (item: any) => props.parent.addEntityAttribute(props.entity.id, item.attribute),
        collect: (monitor) => {
            return {
                canDrop: monitor.isOver({shallow: true}) &&
                    props.entity.attributes.find(a => a === monitor.getItem().attribute) === undefined
            };
        },
    });

    return (
        <div ref={drop} className={'layout horizontal gap box'} style={{
            gap: 20,
            opacity: props.entity.unit.active ? 1 : 0.5,
            border: `solid 1px ${canDrop ? 'var(--tint-color)' : 'var(--overlay-color)'}`}}
        >
            <ImagePackElement id={props.entity.id} size={80}/>
            <AssetImage id={`${props.entity.id}_passive`}/>
            <div className={'layout vertical gap'}>
                <div className={'layout horizontal gap'}>
                    <LocalizationInput style={{width: 100}} id={props.entity.id}/>
                    <BooleanInput value={props.entity.unit.active} onChange={(value) => {
                        props.entity.unit.active = value;
                        executeCommand(new UpdateUnitCommand(props.entity.unit));
                    }}/>
                    <IconButton icon={'trash'} style={{opacity: deleteDisabled ? 0.5 : 1}} onClick={() => {
                        if (!deleteDisabled) {
                            props.parent.deleteEntity(props.entity.id);
                        }
                    }}/>
                    <IconButton icon={'arrow-down'} onClick={() => {
                        executeCommand(new ChangeConstantsCommand(data => {
                            data.units = moveObjectDown(data.units, props.entity.unit);
                        }));
                    }}/>
                    <IconButton icon={'arrow-up'} onClick={() => {
                        executeCommand(new ChangeConstantsCommand(data => {
                            data.units = moveObjectUp(data.units, props.entity.unit);
                        }));
                    }}/>
                </div>
            </div>
            <div className={'layout vertical gap'}>
                <p className={'text header'}>Statistics</p>
                {props.entity.statistics.map((statistic, i) => {
                    return <NumberInputLabel key={i} title={statistic.name} value={statistic.value}
                                             onChange={statistic.onChange}/>
                })}
            </div>
            <div className={'layout vertical gap'}
                 style={{
                     borderRadius: '5px',
                     flexGrow: 1
                 }}>
                <p className={'text header'}>Attributes</p>
                {props.entity.attributes.map((uid, i) => {
                    return <MetadataLabel key={i} uid={uid} onDelete={() => props.parent.deleteEntityAttribute(props.entity.id, uid)}/>
                })}
            </div>
        </div>
    )
}

function MetadataDragLabel(props: {uid: string, index: number, onDelete(): void}) {
    const [{ opacity }, drag] = useDrag({
        type: 'attribute',
        item: {
            attribute: props.uid,
        },
        collect: (monitor) => {
            const isDragging = monitor.isDragging();
            const opacity = isDragging ? 0.5 : 1;
            return {
                opacity: opacity,
            };
        },
    });

    const style = {
        opacity: opacity,
    };

    return (
        <div ref={drag} style={style}>
            <MetadataLabel uid={props.uid}/>
        </div>
    );
}

export default EntityEditor;