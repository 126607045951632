import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class UpdateTechTierPriceCommand extends CommandBase {
    tier: number;
    price: number;

    constructor(tier: number, price: number) {
        super();
        this.tier = tier;
        this.price = price;
    }

    execute(data: RemoteConstantsModel): boolean {
        if (data.techConfig === undefined) {
            data.techConfig = {
                prices: []
            };
        }

        const len = data.techConfig.prices.length;
        if (this.tier >= len) {
            for (let i = 0; i < this.tier - len; i++) {
                data.techConfig.prices[len + i] = 0;
            }
        }

        data.techConfig.prices[this.tier] = this.price;
        return true;
    }
}