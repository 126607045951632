import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveUnitCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.units.findIndex(u => u.id === this.id);
        if (index < 0) {
            return false;
        }

        data.units.splice(index, 1);
        for (const key in data.localization) {
            delete data.localization[key][this.id];
        }

        if (this.id === data.startingUnit) {
            data.startingUnit = data.units[0].id;
        }

        return true;
    }
}