import {Vector2} from "../models/math.model";

export function findCenterWithDirectionalOffset(point1: Vector2, point2: Vector2, offsetMagnitude: number): Vector2 {
    const center: Vector2 = {
        x: (point1.x + point2.x) / 2,
        y: (point1.y + point2.y) / 2
    };

    const vector: Vector2 = {
        x: point2.x - point1.x,
        y: point2.y - point1.y
    };

    const vectorLength = Math.sqrt(vector.x ** 2 + vector.y ** 2);
    if (vectorLength === 0) {
        return center;
    }

    const unitVector: Vector2 = {
        x: vector.x / vectorLength,
        y: vector.y / vectorLength
    };

    const perpendicularVector: Vector2 = {
        x: center.x < 0 ? unitVector.y : -unitVector.y,
        y: unitVector.x
    };

    return {
        x: center.x - perpendicularVector.x * offsetMagnitude,
        y: center.y - perpendicularVector.y * offsetMagnitude
    };
}