import {CSSProperties} from "react";

interface IconButtonProps {
    icon: string;
    onClick(): void;
    style?: CSSProperties | undefined;
}

function IconButton(props: IconButtonProps) {
    return (
        <button onClick={props.onClick} style={props.style}>
            <span className={`fa-solid fa-${props.icon}`}/>
        </button>
    )
}

export default IconButton;