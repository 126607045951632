import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class ChangeLocalizationValuesCommand extends CommandBase {
    key: string;
    value: string;

    constructor(key: string, value: string) {
        super();
        this.key = key;
        this.value = value;
    }

    execute(data: RemoteConstantsModel): boolean {
        for (const language in data.localization) {
            data.localization[language][this.key] = this.value;
        }

        return true;
    }
}