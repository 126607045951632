import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddSoundCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.sounds ??= {};
        data.sounds[this.id] = {
            fileName: '',
            channels: 1,
            frequency: 44100
        }

        return true;
    }
}