import {CSSProperties} from "react";
import {useApp} from "../app.context";

export interface SelectableProps<T> {
    value: T;
    options: T[];
    onChange(value: T): void;
    style?: CSSProperties;
}

function Selectable<T extends string | number>(props: SelectableProps<T>) {
    const {getLocalizationValue} = useApp();

    return (
        <select defaultValue={props.value} onChange={(v) => {
            props.onChange(v.target.value as T);
        }}>
            {props.options.map((id, index) => {
                return <option key={index} value={id}>{getLocalizationValue(id.toString())}</option>
            })}
        </select>
    )
}

export default Selectable;