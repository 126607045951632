import MetadataDragLabel from "../../elements/metadataDragLabel";
import {useIntegrator} from "../../integrator.context";
import Selectable from "../../elements/selectable.element";
import {useState} from "react";
import {DropTargetMonitor, useDrop} from "react-dnd";
import {AddBuildingLevelCommand} from "../../commands/addBuildingLevel.command";
import MetadataLabel from "../../elements/metadataLabel.element";
import {RemoveBuildingRewardCommand} from "../../commands/removeBuildingReward.command";
import {RemoveBuildingLevelCommand} from "../../commands/removeBuildingLevel.command";
import {AddBuildingRewardCommand} from "../../commands/addBuildingReward";

function RewardsEditor() {
    const {state, executeCommand} = useIntegrator();
    const [selectedId, setSelectedId] = useState<string>(state.buildings[0].id);

    function getBuildingRewards(): string[][] {
        return state.buildings.find(b => b.id === selectedId)?.rewards ?? [];
    }

    return (
        <div className={'layout horizontal'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout vertical gap'} style={{gap: 10, flexGrow: 1}}>
                <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', gap: 10, height: 0}}>
                    {getBuildingRewards().map((rewards, index) => {
                        return <LevelItem buildingId={selectedId} key={index} level={index} rewards={rewards}/>
                    })}
                </div>
                <button onClick={() => executeCommand(new AddBuildingLevelCommand(selectedId))}>Add Level</button>
            </div>
            <div className={'layout vertical gap'} style={{gap: 10}}>
                <Selectable value={selectedId} options={state.buildings.map(b => b.id)} onChange={v => setSelectedId(v)}/>
                <div className={'layout vertical gap box flex-grow'}>
                    <p className={'text header'}>{`Rewards`}</p>
                    <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', height: 0, width: '25vw'}}>
                        {state.rewards !== undefined && state.rewards !== null && state.rewards.map((metadata, index) => {
                            return <MetadataDragLabel key={index} uid={metadata.uid} type={'attribute'}
                                                      draggable={() => true}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

function LevelItem(props: {buildingId: string, level: number, rewards: string[]}) {
    const {state, executeCommand} = useIntegrator();

    const building = state.buildings.find(b => b.id === props.buildingId)

    function checkCanDrop(monitor: DropTargetMonitor<any, void>): boolean {
        if (building === undefined || !monitor.isOver({shallow: true})) {
            return false;
        }

        return !building.rewards.includes(monitor.getItem().id);
    }

    const [{canDrop}, drop] = useDrop({
        accept: 'attribute',
        drop: (item: any, monitor) => {
            if (!checkCanDrop(monitor)) {
                return;
            }

            executeCommand(new AddBuildingRewardCommand(props.buildingId, item.id, props.level));
        },
        collect: (monitor) => {
            return {
                canDrop: checkCanDrop(monitor)
            };
        },
    });

    return (
        <div ref={drop} className={'box'} style={{
            border: `solid 1px ${canDrop ? 'var(--tint-color)' : 'var(--overlay-color)'}`
        }}>
            <div className={'layout vertical gap'}>
                <div className={'layout horizontal stretch center gap'}>
                    <p className={'text header'}>{`Level ${props.level + 1}`}</p>
                    <button onClick={() =>
                        executeCommand(new RemoveBuildingLevelCommand(props.buildingId, props.level))
                    }>
                        <span className={'fa-solid fa-trash'}/>
                    </button>
                </div>
                {props.rewards.map((reward, index) => {
                    return <MetadataLabel key={index} uid={reward} onDelete={() =>
                        executeCommand(new RemoveBuildingRewardCommand(props.buildingId, reward, props.level))
                    }/>
                })}
            </div>
        </div>
    )
}

export default RewardsEditor;