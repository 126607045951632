import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveFileArchiveCommand extends CommandBase {
    value: string;

    constructor(value: string) {
        super();
        this.value = value;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.fileArchive.findIndex(f => f === this.value);
        if (index < 0) {
            return false;
        }

        data.fileArchive.splice(index, 1);
        return true;
    }
}