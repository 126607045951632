import {CommandBase} from "./commandBase";
import {RemoteConstantsModel, ResourceData} from "../models/remoteConstants.model";

export class UpdateResourceCommand extends CommandBase {
    resource: ResourceData;

    constructor(resource: ResourceData) {
        super();
        this.resource = resource;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.resources.findIndex(u => u.id === this.resource.id);
        if (index < 0) {
            return false;
        }

        data.resources[index] = this.resource;
        return true;
    }
}