import {useIntegrator} from "../../integrator.context";
import {TechTreeComponent} from "../../models/remoteConstants.model";
import {DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {AddTechUnlockCommand} from "../../commands/addTechUnlock.command";
import {AddTechDependencyCommand} from "../../commands/techDependency.command";
import LocalizationInput from "../../elements/localizationInput.element";
import {AddTechNodeCommand} from "../../commands/addTechNode.command";
import {RemoveTechNodeCommand} from "../../commands/removeTechNode.command";
import TechUnlock from "./techUnlock";
import {TechNodeData} from "../../models/techTree.model";
import {RemoveTechUnlockCommand} from "../../commands/removeTechUnlock.command";

enum NodeDropState {
    Add,
    Remove,
    Inactive
}

function TreeNode(props: { node: TechNodeData, cancelDrag(): void }) {
    const {state, executeCommand} = useIntegrator();

    const node = state.techTree.find(t => t.id === props.node.id) ?? {} as TechTreeComponent;

    function getDropState(monitor: DropTargetMonitor<any, void>): NodeDropState {
        if (!monitor.isOver({shallow: true})) {
            return NodeDropState.Inactive;
        }

        const type = monitor.getItemType();
        const item = monitor.getItem();
        switch (type) {
            case 'entity':
                return NodeDropState.Add;
            case 'node':
                const isThis = item.nodeId === props.node.id;
                const isChild = node.children.includes(item.nodeId);
                if (isThis) {
                    return NodeDropState.Inactive;
                }

                return isChild ? NodeDropState.Remove : NodeDropState.Add;
            default:
                return NodeDropState.Inactive;
        }
    }

    function executeDrop(item: any, monitor: DropTargetMonitor<any, void>) {
        props.cancelDrag();

        const dropState = getDropState(monitor);
        if (dropState === NodeDropState.Inactive) {
            return;
        }

        const type = monitor.getItemType();
        switch (type) {
            case 'entity':
                executeCommand(new RemoveTechUnlockCommand(item.nodeId, item.id));
                executeCommand(new AddTechUnlockCommand(props.node.id, item.id));
                break;
            case 'node':
                executeCommand(new AddTechDependencyCommand(item.nodeId, props.node.id))
                break;
        }
    }

    function getDropColor(dropState: NodeDropState): string {
        switch (dropState) {
            case NodeDropState.Add:
                return 'var(--tint-color)';
            case NodeDropState.Remove:
                return 'var(--error-color)';
            default:
                return 'var(--overlay-color)'
        }
    }

    const [{dropState}, drop] = useDrop({
        accept: ['entity', 'node'],
        drop: (item: any, monitor) => executeDrop(item, monitor),
        collect: (monitor) => {
            return {
                dropState: getDropState(monitor)
            };
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: 'node',
        item: {
            nodeId: props.node.id
        },
        end: () => {
            props.cancelDrag();
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        }
    });

    return (
        <div ref={drag} style={{
            opacity: isDragging ? 0.5 : 1,
            width: "min-content"
        }}>
            <div ref={drop} className={'layout vertical gap'} style={{
                position: 'relative',
                width: 'fit-content',
                borderRadius: 'var(--border-radius)',
                backgroundColor: 'var(--box-color)',
                border: `1px solid ${getDropColor(dropState)}`,
                padding: '5px',
                margin: '2px'
            }}>
                <div className={'layout horizontal center gap'}>
                    <LocalizationInput id={props.node.id} style={{width: 80}}/>
                    <button onClick={() => executeCommand(new AddTechNodeCommand(props.node.id))}><span
                        className={'fa-solid fa-add'}/></button>
                    <button onClick={() => executeCommand(new RemoveTechNodeCommand(props.node.id))}><span
                        className={'fa-solid fa-trash'}/></button>
                </div>
                <div className={'layout horizontal gap'}>
                    {(node.unlocks === undefined || node.unlocks.length === 0) &&
                        <div style={{
                            width: 30,
                            height: 30,
                            border: 'solid 1px var(--overlay-color)',
                            borderRadius: 'var(--border-radius)'
                        }}
                        />
                    }
                    {node.unlocks !== undefined && node.unlocks.length > 0 && node.unlocks.map((id, index) => {
                        return <TechUnlock key={index} nodeId={props.node.id} id={id} cancelDrag={props.cancelDrag}/>
                    })}
                </div>
            </div>
        </div>
    );
}

export default TreeNode;