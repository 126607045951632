import {TechTreeComponent} from "../../models/remoteConstants.model";
import {useEffect, useRef, useState} from "react";
import {useIntegrator} from "../../integrator.context";
import {AddTechNodeCommand} from "../../commands/addTechNode.command";
import TreeNode from "./techNode";
import {TechNodeData} from "../../models/techTree.model";
import {findCenterWithDirectionalOffset} from "../../utils/math.utils";

const buildHierarchy = (data: TechTreeComponent[]): TechNodeData => {
    const map = new Map<string | null, TechNodeData>();
    data.forEach(tech => map.set(tech.id, {
        id: tech.id,
        tech: tech,
        children: []
    }));

    let root: TechNodeData = map.get('root')!;
    data.slice().reverse().forEach(tech => {
        const node = map.get(tech.id)!;
        const parent = data.find(t => t.children.includes(tech.id));
        if (parent !== undefined) {
            map.get(parent.id)?.children.push(node);
        }
    });

    return root;
};

const calculatePositions = (node: TechNodeData, depth: number = 0, angle: number = 0, t: number = 1) => {
    const radius = 200 + 280 * (depth - 1);
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    node.x = x;
    node.y = y;
    node.depth = depth;
    node.angle = angle;

    const childrenCount = node.children.length;
    node.children.forEach((child, i) => {
        const childAngle = angle + evaluateAngle(i, childrenCount, t) - evaluateAngle(childrenCount - 1, childrenCount, t) / 2;
        calculatePositions(child, depth + 1, childAngle, 4 + depth * 4);
    });
};

function evaluateAngle(i: number, len: number, t: number) {
    return (i * (Math.PI / t)) / ((len) / 2);
}

function CircularTree() {
    const {state} = useIntegrator();
    const [nodes, setNodes] = useState<TechNodeData[]>([]);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const dragStartRef = useRef<{ x: number, y: number } | null>(null);

    useEffect(() => {
        const hierarchy = buildHierarchy(state.techTree);
        calculatePositions(hierarchy);
        const flatNodes: TechNodeData[] = [];
        const flatten = (node: TechNodeData) => {
            flatNodes.push(node);
            node.children.forEach(flatten);
        };
        flatten(hierarchy);
        setNodes(flatNodes);
    }, [state]);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setDragging(true);
        dragStartRef.current = { x: event.clientX - position.x, y: event.clientY - position.y };
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (dragging && dragStartRef.current) {
            setPosition({
                x: event.clientX - dragStartRef.current.x,
                y: event.clientY - dragStartRef.current.y,
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
        dragStartRef.current = null;
    };

    function getParents(id: string): TechNodeData[] {
        const parents: TechNodeData[] = [];
        for (let i = 0; i < nodes.length; i++) {
            const isParent = nodes[i].tech.children.includes(id);
            if (isParent) {
                parents.push(nodes[i]);
            }
        }

        return parents;
    }

    return (
        <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
        }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}>
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                transition: 'unset',
                left: `${position.x}px`,
                top: `${position.y}px`,
                backgroundColor: 'transparent'
            }}>
                {nodes.map((node, index) => {
                    const parents = getParents(node.id);
                    const parentIsRoot = parents.find(p => p.id === 'root') !== undefined;
                    const hasMultipleParents = parents.length === 2;

                    let x = node.x  || 0;
                    let y = node.y  || 0;
                    if (hasMultipleParents) {
                        const center = findCenterWithDirectionalOffset(
                            {x: parents[0].x || 0, y: parents[0].y || 0},
                            {x: parents[1].x || 0, y: parents[1].y || 0}, 300);

                        x = center.x;
                        y = center.y;
                    }

                    return (
                        <div key={index} style={{
                            position: 'absolute',
                            left: `calc(50% + ${x}px)`,
                            top: `calc(50% + ${y}px)`,
                            width: 0,
                            height: 0
                        }}>
                            {parents.map((parent, index) => {
                                return <DrawLine key={index}
                                    x1={400 + x}
                                    y1={400 + y}
                                    x2={400 + (parentIsRoot ? 0 : parent.x || 0)}
                                    y2={400 + (parentIsRoot ? 0 : parent.y || 0)}
                                />
                            })}
                            {node.depth === 0 && <RootNode node={node}/>}
                            {node.depth !== 0 && <TreeNode node={node} cancelDrag={handleMouseUp}/>}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

function DrawLine(props: {x1: number, y1: number, x2: number, y2: number}) {
    const length = Math.sqrt((props.x2 - props.x1) ** 2 + (props.y2 - props.y1) ** 2);
    const angle = Math.atan2(props.y2 - props.y1, props.x2 - props.x1) * (180 / Math.PI);
    return (
        <div
            style={{
                position: 'relative',
                transformOrigin: '0 0',
                transform: `translate(100px, 50px) rotate(${angle}deg)`,
                width: `${length}px`,
                height: '2px',
                backgroundColor: 'var(--overlay-color)',
                zIndex: -1
            }}
        />
    );
}

function RootNode(props: { node: TechNodeData }) {
    const {executeCommand} = useIntegrator();

    return (
        <div className={'layout vertical gap'} style={{
            position: 'relative',
            top: '25px',
            left: '50%',
            borderRadius: 'var(--border-radius)',
            backgroundColor: 'var(--box-color)',
            padding: '5px',
            margin: '2px',
            width: 100
        }}>
            <div className={'layout horizontal center gap'}>
                <p style={{width: 80, paddingLeft: 5}}>Root</p>
                <button onClick={() => executeCommand(new AddTechNodeCommand(props.node.id))}>+</button>
            </div>
        </div>
    )
}

export default CircularTree;