import {useApp} from "../app.context";
import {CSSProperties} from "react";
import {useIntegrator} from "../integrator.context";
import {ChangeLocalizationValuesCommand} from "../commands/changeLocalizationValues.command";
import TextArea from "./textArea.element";

interface LocalizationInputProps {
    id: string;
    style?: CSSProperties;
}

function LocalizationTextArea(props: LocalizationInputProps) {
    const {executeCommand} = useIntegrator();
    const {getLocalizationValue} = useApp();

    return <TextArea value={getLocalizationValue(props.id)} onChange={(value) =>
        executeCommand(new ChangeLocalizationValuesCommand(props.id, value))} style={props.style}
    />
}

export default LocalizationTextArea;