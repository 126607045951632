import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddTechDependencyCommand extends CommandBase {
    targetNode: string;
    parentNode: string;

    constructor(targetNode: string, parentNode: string) {
        super();
        this.targetNode = targetNode;
        this.parentNode = parentNode;
    }

    execute(data: RemoteConstantsModel): boolean {
        const index = data.techTree.findIndex(t => t.id === this.parentNode);
        if (index <= 0) {
            return false;
        }

        const alreadyChild = data.techTree[index].children.includes(this.targetNode);
        if (alreadyChild) {
            data.techTree[index].children = data.techTree[index].children.filter(c => c !== this.targetNode);
        } else {
            data.techTree[index].children.push(this.targetNode);
        }

        return true;
    }
}