import {CommandBase} from "./commandBase";
import {ImageMetadata, RemoteConstantsModel} from "../models/remoteConstants.model";

export class UpdateImageCommand extends CommandBase {
    id: string;
    importSettings: ImageMetadata;

    constructor(id: string, importSettings: ImageMetadata) {
        super();
        this.id = id;
        this.importSettings = importSettings;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.images[this.id] = this.importSettings;
        return true;
    }
}