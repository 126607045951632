import {Vector2} from "./math.model";

export interface RemoteConstantsModel {
    frame: number;
    startingUnit: string;
    startingBuilding: string;
    barracksId: string;
    startingGold: number;
    startingGoldPerTurn: number;
    attackDamageFormula: string;
    retaliatoryDamageFormula: string;
    elevationNoise: NoiseMap;
    temperatureNoise: NoiseMap;
    techActionAttributes: Metadata[];
    techPassiveAttributes: Metadata[];
    unitAttributes: Metadata[];
    buildingAttributes: Metadata[];
    buildings: BuildingData[];
    units: UnitData[];
    tiles: TileData[];
    resources: ResourceData[];
    rewards: Metadata[];
    techConfig: TechTreeConfig;
    techTreeRender: TechTreeRenderData;
    techTree: TechTreeComponent[];
    localization: { [key: string]: { [key: string]: string } };
    imagePacks: { [key: string]: string[][] };
    images: { [key: string]: ImageMetadata };
    sounds: { [key: string]: SoundMetadata };
    fileArchive: string[];
}

export interface NoiseMap {
    scale: number;
    octaves: number;
    lacunarity: number;
    persistance: number;
}

export interface Metadata {
    uid: string;
    id: string;
    values: { [key: string]: number };
    entities: { [key: string]: string };
}

export interface ImageMetadata {
    fileName: string;
    pixelMultiplier: number;
    offsetX: number;
    offsetY: number;
}

export interface SoundMetadata {
    fileName: string;
    channels: number;
    frequency: number;
}

export interface BuildingData {
    id: string;
    active: boolean;
    price: number;
    maxLevel: number;
    goldPerTurn: number;
    populationOnCreation: number;
    collectionTrigger: string;
    collectionType: string;
    collectionRequirement: string;
    turnsUntilFirstTrigger: number;
    amountIncreasePerTrigger: number;
    attributes: string[];
    produceableUnits: string[];
    rewards: string[][];
    properties: BuildingPropertyData;
}

export interface BuildingPropertyData {
    visionRange: number;
    triggerCadence: number;
    territoryRange: number;
    startingSlots: number;
}

export interface UnitData {
    id: string;
    active: boolean;
    price: number;
    attributes: string[];
    properties: UnitPropertyData;
}

export interface TileData {
    id: string;
    active: boolean;
    rarity: number;
    elevationNoise: Vector2;
    temperatureNoise: Vector2;
    additionalVisionRange: number;
    movementPrice: number;
    supportingTile: string;
    canMove: boolean;
    canSettle: boolean;
    canSail: boolean;
    canHide: boolean;
    resources: string[];
    buildings: string[];
}

export interface ResourceData {
    id: string;
    active: boolean;
    visionModifier: number;
    startCount: number;
    rarity: number;
    attributes: string[];
}

export interface UnitPropertyData {
    visionRange: number;
    movementSpeed: number;
    range: number;
    health: number;
    attack: number;
    defense: number;
    armour: number;
    regeneration: number;
}

export interface TechTreeRenderData {
    radius: number;
    angleModifier: number;
    spread: number;
    radiusStep: number;
    childrenRadius: number;
    rootRadius: number;
}

export interface TechTreeConfig {
    prices: number[];
}

export interface TechTreeComponent {
    id: string;
    price: number;
    children: string[];
    unlocks: string[];
}

export const CollectionTrigger: string[] = [
    'None',
    'OnPlace',
    'TurnStart',
    'TurnEnd',
    'TilesExplored'
]

export const CollectionType: string[] = [
    'None',
    'Gold',
    'Population'
]

export const CollectionRequirement: string[] = [
    'None',
    'FullTurnOccupied',
    'CloudCount',
    'TurnCount'
]
