import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveLocalizationValueCommand extends CommandBase {
    key: string;

    constructor(key: string) {
        super();
        this.key = key;
    }

    execute(data: RemoteConstantsModel): boolean {
        for (const language in data.localization) {
            delete data.localization[language][this.key];
        }

        return true;
    }
}