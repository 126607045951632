import {useApp} from "../../app.context";
import {useIntegrator} from "../../integrator.context";
import {UnitData} from "../../models/remoteConstants.model";
import AssetImage from "../../elements/assetImage.element";

function UnitView() {
    const {state} = useIntegrator();
    const {unit, setUnit, getLocalizationValue} = useApp();

    const unitData = state.units.find(u => u.id === unit) ?? {} as UnitData;

    function getAttributes(): string {
        let message = 'Attributes: ';

        if (unitData.attributes.length === 0) {
            message += 'None';
        } else {
            message += unitData.attributes.join(', ');
        }

        return message;
    }

    return (
        <div className={'layout vertical overlay'} onClick={() => {
            setUnit(undefined);
        }}>
            <div className={'layout vertical gap box'} style={{padding: 10, gap: 20, width: 400}}
                 onClick={e => e.stopPropagation()}
            >
                <div className={'layout horizontal center stretch gap'}>
                    <div className={'layout horizontal'} style={{alignItems: 'center', gap: 10}}>
                        <AssetImage id={unitData.id} upload={false}/>
                        <p className={'text header'}>{getLocalizationValue(unitData.id)}</p>
                    </div>
                    <div className={'layout horizontal gap'} style={{alignItems: 'center'}}>
                        <p>{unitData.price}</p>
                        <AssetImage id={'gold'} size={30} background={'transparent'} upload={false}/>
                    </div>
                </div>
                <div className={'layout vertical gap'}>
                    <Property title={'Attack'} value={unitData.properties.attack}/>
                    <Property title={'Defense'} value={unitData.properties.defense}/>
                    <Property title={'Armour'} value={unitData.properties.armour}/>
                    <Property title={'HP'} value={unitData.properties.health}/>
                    <Property title={'Move'} value={unitData.properties.movementSpeed}/>
                    <Property title={'Range'} value={unitData.properties.range}/>
                </div>
                <div className={'layout vertical gap'}>
                    <p>{getAttributes()}</p>
                </div>
                <button onClick={() => setUnit(undefined)}>Close</button>
            </div>
        </div>
    )
}

function Property(props: { title: string, value: number }) {
    return (
        <div className={'layout horizontal stretch'}>
            <p style={{whiteSpace: 'pre-wrap'}}>{props.title}</p>
            <p style={{whiteSpace: 'pre-wrap'}}>{props.value}</p>
        </div>
    )
}

export default UnitView;