import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {v4 as uuidv4} from "uuid";
import {ChangeLocalizationValuesCommand} from "./changeLocalizationValues.command";
import {AddImagePackCommand} from "./addImagePack.command";

export class AddTileCommand extends CommandBase {
    execute(data: RemoteConstantsModel): boolean {
        const id = uuidv4();
        data.tiles ??= [];
        data.tiles.push({
            id: id,
            active: true,
            rarity: 100,
            elevationNoise: {
                x: 0,
                y: 1
            },
            temperatureNoise: {
                x: 0,
                y: 1
            },
            additionalVisionRange: 0,
            movementPrice: 0,
            supportingTile: '',
            canMove: true,
            canSettle: false,
            canSail: false,
            canHide: false,
            resources: [],
            buildings: []
        });

        new ChangeLocalizationValuesCommand(id, 'New Tile').execute(data);
        new AddImagePackCommand(id).execute(data);
        return true;
    }
}